import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Categoria } from '../shared/interfacce';
import { AggiungiCategoriaComponent } from './aggiungi-categoria/aggiungi-categoria.component';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {

  constructor(private dialog: MatDialog, private location: Location, private apiService: ApiService) { }

  categorie: Categoria[] = [];

  nomeFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    this.apiService.setCategorie();
    this.apiService.categorieChanged.subscribe({
      next: (data) => { this.categorie = data; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamentoNome(a: Categoria, b: Categoria) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoDescrizione(a: Categoria, b: Categoria) {
    return (a.descrizione || '').localeCompare((b.descrizione || ''))
  }

  onAdd() {

    this.dialog.closeAll();
    this.dialog.open(AggiungiCategoriaComponent, { autoFocus: false, width: '1000px' })

  }

}
