import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-codici-sconto',
  templateUrl: './elimina-codici-sconto.component.html',
  styleUrls: ['./elimina-codici-sconto.component.css']
})
export class EliminaCodiciScontoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EliminaCodiciScontoComponent>, @Inject(MAT_DIALOG_DATA) public data : {id: number}, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
  }

  onDelete() {

    this.apiService.deleteCodiceSconto(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/codici-sconto'])
    })  

  }

  onClose() {
    this.dialogRef.close();
  }

}
