import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Cliente, Gruppo } from 'src/app/shared/interfacce';
import { ApiService } from 'src/app/service/api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { checkImage } from 'src/app/shared/controlli';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AggiungiClienteComponent } from 'src/app/clienti/aggiungi-cliente/aggiungi-cliente.component';

@Component({
  selector: 'app-modifica-gruppo',
  templateUrl: './modifica-gruppo.component.html',
  styleUrls: ['./modifica-gruppo.component.css']
})
export class ModificaGruppoComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<ModificaGruppoComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { id: number, id_cliente: number }) { }

  membri: Cliente[] = [];
  clienti: Cliente[] = [];

  cliente?: Cliente;
  gruppo?: Gruppo;

  filteredClienti: Cliente[] = [];

  formGruppo!: FormGroup;
  formRicerca!: FormGroup;

  immagineList: NzUploadFile[] = [];
  immaginePath!: string;

  errore: boolean = false;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica del gruppo' },
      { type: 'minlength', message: 'Inserire almeno 5 caratteri' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ],
    'membro': [
      { type: 'duplicato', message: "Membro già presente, selezionarne o crearne un altro!" }
    ]
  }

  ngOnInit(): void {

    this.formGruppo = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      descrizione: new FormControl(null),
      immagine: new FormControl(null, checkImage()),
    })

    this.formRicerca = new FormGroup({
      cliente: new FormControl(null)
    })

    // Recupero i dati del gruppo
    this.apiService.getGruppo(this.data.id).subscribe({
      next: (data) => this.gruppo = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        this.formGruppo.setValue({
          nome: this.gruppo?.nome,
          descrizione: this.gruppo?.descrizione,
          immagine: ''
        })

        // Recupero i membri
        this.apiService.setMembri(this.gruppo!.id);
        this.apiService.membriChanged.subscribe({
          next: (data) => { this.membri = data; this.getClienti() },
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => { }
        })

      }
    })

  }

  getClienti() {

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.filteredClienti = this.clienti.slice(0, 10)
    })

  }

  mostraValore(cliente: Cliente): string {
    var valore = cliente && cliente.nome ? cliente.cognome.trim() + ' ' + cliente.nome.trim() : '';
    return valore;
  }

  filtra(name: string): Cliente[] {
    const filterValue = name.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.filtra(ricerca).slice(0, 10)
  }

  onCliente(cliente: Cliente) {
    this.cliente = cliente;
  }

  onClienti() {
    var dialogRef = this.dialog.open(AggiungiClienteComponent, { data: { clienti: this.clienti }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(esito => {
      if (esito) {
        this.getClienti();
      }
    })
  }

  onDelete(cliente: number) {

    this.apiService.deleteMembroGruppo(this.data.id, cliente).subscribe({
      next: (data) => this.apiService.setMembri(this.data.id),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  membroPresente(id: number) {
    let esiste = false;
    this.membri.forEach(m => {
      if (id == m.id) {
        esiste = true;
      }
    });
    return esiste;
  }

  onAdd() {

    if (!this.membroPresente(this.cliente!.id)) {

      this.apiService.addMembroGruppo(this.data.id, this.cliente!.id).subscribe({
        next: (data) => this.apiService.setMembri(this.data.id),
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => this.formRicerca.patchValue({ cliente: null })
      })

    } else {
      this.errore = true;
    }
  }

  beforeImmagineUpload = (file: any): boolean => {
    this.immagineList = this.immagineList.concat(file);
    this.formGruppo.patchValue({ immagine: file.name });

    const isJpgOrPng = this.immagineList.length > 0 ? this.immagineList[0]!.type === 'image/jpeg' || this.immagineList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.immagineList = [];
      // this.formProdotti.patchValue({ immagine: '' });           
      return false;
    } else {

      const reader = new FileReader();
      reader.onload = () => {
        this.immaginePath = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImmagineUpload = (file: any): boolean => {
    this.formGruppo.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onSubmit() {
    let gruppo = {
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.formGruppo.get('immagine')?.value || "",
      file_immagine: this.immaginePath || "",
    }

    this.apiService.updateGruppo(this.data.id, gruppo).subscribe({
      next: (data) => this.dialogRef.close(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }
}


