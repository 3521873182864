import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Tesseramento } from '../shared/interfacce';
import * as XLSX from 'xlsx';
import { AggiungiTesseramentoComponent } from './aggiungi-tesseramento/aggiungi-tesseramento.component';
import { EsportazioneTesseramentoComponent } from './esportazione-tesseramento/esportazione-tesseramento.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-tesseramenti',
  templateUrl: './tesseramenti.component.html',
  styleUrls: ['./tesseramenti.component.css']
})
export class TesseramentiComponent implements OnInit {

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  carica: boolean = true;
  loading: boolean = true;
  tesseramenti: Tesseramento[] = [];

  clienteFilter: any = '';
  prodottoFilter: any = '';
  statoFilter: any = 'valido';

  filtri: { pageIndex: number, pageSize: number, cliente: string, prodotto: string, stato: string } = { pageIndex: 1, pageSize: 10, cliente: '', prodotto: '', stato: 'valido' };

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {
    this.loading = true;

    // Recupero i tesseramenti
    this.apiService.setTesseramenti(this.pageIndex, 10, 'tesseramenti.data_creazione', 'descend', this.clienteFilter, this.prodottoFilter, this.statoFilter);
    this.apiService.tesseramentiChanged.subscribe({
      next: (data) => {
        this.tesseramenti = data;
        this.totale = this.apiService.TotaleTesseramenti;

        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  loadDataFromServer(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setTesseramenti(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.prodottoFilter, this.statoFilter);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, prodotto: this.prodottoFilter, stato: this.statoFilter };

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'tesseramenti.data_creazione', value: 'descend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.prodottoFilter != this.filtri.prodotto || this.statoFilter != this.filtri.stato)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.prodottoFilter != this.filtri.prodotto || this.statoFilter != this.filtri.stato)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiTesseramentoComponent, { autoFocus: false, width: '1000px' })
  }

  onOrdinamento(a: Tesseramento, b: Tesseramento) {
    return 1 - 1;
  }

  onExportGiornalieri() {

    // Recupero i tesseramenti giornalieri
    this.apiService.getTesseramentiGiornalieri().subscribe({
      next: (data) => {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(data);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 30 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Dettaglio Ordini');
        XLSX.writeFile(workBook, 'dettaglio_ordini_giornalieri.xlsx');
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })
    
  }

  onExport() {
    this.dialog.closeAll();
    this.dialog.open(EsportazioneTesseramentoComponent, { autoFocus: false, width: '1000px' });
  }

  onIndietro() {
    this.location.back();
  }

}
