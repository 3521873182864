import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/shared/interfacce';
import { DatePipe } from '@angular/common';
import { checkImage, emailPresente } from 'src/app/shared/controlli';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-aggiungi-cliente',
  templateUrl: './aggiungi-cliente.component.html',
  styleUrls: ['./aggiungi-cliente.component.css']
})
export class AggiungiClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<AggiungiClienteComponent>, @Inject(MAT_DIALOG_DATA) public data: { clienti: Cliente[] }, private datePipe: DatePipe) { }

  formCliente!: FormGroup;
  clienti: Cliente[] = [];

  hide: boolean = true;

  immaginePath!: string;
  immagineList: NzUploadFile[] = [];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del cliente' }
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la creazione del cliente' }
    ],
    'password': [
      { type: 'pattern', message: 'Inserire almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale' }
    ],
    'codice_fiscale': [
      { type: 'required', message: 'Inserire un codice fiscale per procedere con la creazione del cliente' },
      { type: 'pattern', message: 'Inserire un codice fiscale valido' }
    ],
    'data_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la creazione del cliente' }
    ],
    'email': [
      { type: 'email', message: "Inserire un'email valida per procedere con la creazione del cliente" },
      { type: 'emailPresente', message: "Email già utilizzata da un altro cliente, inserirne un'altra oppure rimuoverla!" }
    ],
    'telefono': [
      { type: 'pattern', message: 'Inserire un numero di telefono valido, di 9 o 10 cifre' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ]
  }

  ngOnInit(): void {

    this.formCliente = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')),
      codice_fiscale: new FormControl(null, [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null),
      provincia_nascita: new FormControl(null),
      nazione_nascita: new FormControl(null),
      email: new FormControl(null, Validators.email),
      nazione: new FormControl(null),
      provincia: new FormControl(null),
      comune: new FormControl(null),
      indirizzo: new FormControl(null),
      telefono: new FormControl(null, Validators.pattern('^(?=[0-9]*$)(?:.{9}|.{10})$')),
      immagine: new FormControl(null, checkImage()),
      newsletter: new FormControl(null)
    })

    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {}
    })

  }

  onEmail() {
    this.formCliente.get('email')?.clearValidators();
    this.formCliente.get('email')?.setValidators([Validators.email, emailPresente(this.clienti)]);
    this.formCliente.get('email')?.updateValueAndValidity();
  }

  beforeImmagineUpload = (file: any): boolean => {
    this.immagineList = this.immagineList.concat(file);
    this.formCliente.patchValue({ immagine: file.name });

    const isJpgOrPng = this.immagineList.length > 0 ? this.immagineList[0]!.type === 'image/jpeg' || this.immagineList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.immagineList = [];
      // this.formProdotti.patchValue({ immagine: '' });           
      return false;
    } else {

      const reader = new FileReader();
      reader.onload = () => {
        this.immaginePath = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImmagineUpload = (file: any): boolean => {
    this.formCliente.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onClose() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    let cliente = {
      nome: this.formCliente.get('nome')?.value,
      cognome: this.formCliente.get('cognome')?.value,
      password: this.formCliente.get('password')?.value,
      codice_fiscale: this.formCliente.get('codice_fiscale')?.value,
      data_nascita: this.datePipe.transform(this.formCliente.get('data_nascita')?.value, 'yyyy-MM-dd'),
      comune_nascita: this.formCliente.get('comune_nascita')?.value,
      provincia_nascita: this.formCliente.get('provincia_nascita')?.value,
      nazione_nascita: this.formCliente.get('nazione_nascita')?.value,
      email: this.formCliente.get('email')?.value,
      nazione: this.formCliente.get('nazione')?.value,
      provincia: this.formCliente.get('provincia')?.value,
      comune: this.formCliente.get('comune')?.value,
      indirizzo: this.formCliente.get('indirizzo')?.value,
      telefono: this.formCliente.get('telefono')?.value,
      immagine: this.formCliente.get('immagine')?.value || "",
      file_immagine: this.immaginePath || "",
      newsletter: this.formCliente.get('newsletter')?.value || false
    }

    this.apiService.addCliente(cliente).subscribe({
      next: (data) => this.apiService.setClienti(1, 10, 'id', 'ascend', '', '', false),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })
  }
}