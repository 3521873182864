import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { confirmPassword } from '../shared/controlli';
import { AuthUser } from '../shared/interfacce';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.css']
})
export class ImpostazioniComponent implements OnInit {

  hide: boolean = false;
  hide_confirm: boolean = false;

  formSetting!: FormGroup;

  user!: AuthUser;

  error_messages = {
    'password': [
      { type: 'required', message: 'Inserire la nuova password per procedere con la modifica della password' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' },
      { type: 'passwordNotMatch', message: 'Le due password non combaciano!' },
    ],
    'confirm_password': [
      { type: 'required', message: 'Confermare la nuova password per procedere con la modifica della password' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' },
      { type: 'passwordNotMatch', message: 'Le due password non combaciano!' },
    ],
  }


  constructor(private dialogRef: MatDialogRef<ImpostazioniComponent>, private apiService: ApiService, private storageService: StorageService) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();

    this.formSetting = new FormGroup({
      password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])),
      confirm_password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])),
    })
  }

  onClose() {
    this.dialogRef.close(true)
  }

  onConfirmPassoword() {
    let password = this.formSetting.get('password')?.value;
    let confirm_password = this.formSetting.get('confirm_password')?.value;

    this.formSetting.get('password')?.clearValidators;
    this.formSetting.get('confirm_password')?.clearValidators;
    this.formSetting.get('password')?.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30), confirmPassword(password, confirm_password)]);
    this.formSetting.get('confirm_password')?.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30), confirmPassword(password, confirm_password)]);

    this.formSetting.get('password')?.updateValueAndValidity();
    this.formSetting.get('confirm_password')?.updateValueAndValidity();
  }

  onSubmit() {
    let password = this.formSetting.get('password')?.value;

    this.apiService.updatePasswordUtente(this.user.id, password).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

}