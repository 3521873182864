import { Component, OnInit } from '@angular/core';
import { ModificaPuntoRitiroComponent } from '../modifica-punto-ritiro/modifica-punto-ritiro.component';
import { EliminaPuntoRitiroComponent } from '../elimina-punto-ritiro/elimina-punto-ritiro.component';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PuntoRitiro } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-dettaglio-punto-ritiro',
  templateUrl: './dettaglio-punto-ritiro.component.html',
  styleUrls: ['./dettaglio-punto-ritiro.component.css']
})
export class DettaglioPuntoRitiroComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  id!: number;

  carica: boolean = true;
  
  punto_ritiro?: PuntoRitiro;      

  ngOnInit(): void {

    // Recupero l'id dall'url
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getData();
    });
  }

  getData() {

    // Recupero il punto di ritiro in base dell'id
    this.apiService.getPuntoRitiro(this.id).subscribe({
      next: (data) => this.punto_ritiro = data,
      error: (err) => this.router.navigate(['/punti-ritiro']),
      complete: () => this.carica = false
    })

  }

  onIndietro() {
    this.router.navigate(['/punti-ritiro'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaPuntoRitiroComponent, { data: { id: this.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }


  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaPuntoRitiroComponent, { data: { id: this.id }, autoFocus: false, width: '750px' })
  }

}
