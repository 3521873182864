import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-aggiungi-configurazione',
  templateUrl: './aggiungi-configurazione.component.html',
  styleUrls: ['./aggiungi-configurazione.component.css']
})
export class AggiungiConfigurazioneComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiConfigurazioneComponent>, private apiService: ApiService) { }

  formConfigurazione!: FormGroup;    

  error_messages = {
    'valore': [
      { type: 'required', message: "Inserire un valore per procedere con la creazione della configurazione"},            
    ]
  }

  ngOnInit(): void {

    this.formConfigurazione = new FormGroup({      
      descrizione: new FormControl(null),
      valore: new FormControl(null, Validators.required)
    })

  }

  onSubmit() {

    let configurazione = {      
      descrizione: this.formConfigurazione.get('descrizione')?.value || '',
      valore: this.formConfigurazione.get('valore')?.value,      
    }

    this.apiService.addConfigurazione(configurazione).subscribe({
      next: (data) => this.apiService.setConfigurazioni(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })   

  }

  onClose() {
    this.dialogRef.close();
  }

}
