import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente } from 'src/app/shared/interfacce';
import { SuccessComponent } from 'src/app/success/success.component';
import { StampaDettaglioOrdineComponent } from '../stampa-dettaglio-ordine/stampa-dettaglio-ordine.component';

@Component({
  selector: 'app-email-dettaglio-ordine',
  templateUrl: './email-dettaglio-ordine.component.html',
  styleUrls: ['./email-dettaglio-ordine.component.css']
})
export class EmailDettaglioOrdineComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EmailDettaglioOrdineComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { clienti: [{ cliente: string, id_cliente: number }], id: number }) { }

  clienti: Cliente[] = [];
  step: number = 0;

  formRicevuta!: FormGroup;

  error_messages = {
    'email': [
      { type: 'required', message: "Inserire un indirizzo email per inviare la ricevuta dell'ordine" },
    ],
  }

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formRicevuta = new FormGroup({
      email: new FormControl(null, Validators.required)
    })

    // Ciclo tra i clienti passati al modal
    for (let cliente of this.data.clienti) {

      // Recupero i dati del cliente
      this.apiService.getCliente(cliente.id_cliente).subscribe({
        next: (data) => {
          // Se il cliente ha un'email procedo al prossimo step
          this.step = !data.email ? 1 : 0;

          // Aggiungo l'email all'array
          this.clienti?.push(data);
        },
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => {}
      })

    }

  }

  onClose() {
    this.dialogRef.close(false);
  }

  onEmail(step: number) {
    if (step == 0)
      this.step++;
    else
      this.step--
  }

  onRicevuta() {

    // Formo l'oggetto per l'api
    let email = this.formRicevuta.get('email')?.value || '';

    // Associo l'email inserita nel form se il cliente non ha un'email
    if (!this.clienti[0].email || email.length > 0)
      this.clienti[0].email = email;

    // Faccio partire il loader
    let dialogLoader = this.dialog.open(StampaDettaglioOrdineComponent, { autoFocus: false, width: '200px' })

    // Invio l'email con la ricevuta ai clienti selezionati
    this.apiService.inviaOrdine(this.data.id, this.clienti).subscribe({
      next: (data) => dialogLoader.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.dialog.open(SuccessComponent, { autoFocus: false });
        this.dialogRef.close(true);
      }
    })
  }

}
