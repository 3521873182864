import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Cliente, Contatto, Tesseramento, Gruppo, Movimenti } from '../../shared/interfacce';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ModificaContattoClienteComponent } from '../modifica-contatto-cliente/modifica-contatto-cliente.component';
import { ModificaClienteComponent } from '../modifica-cliente/modifica-cliente.component';
import { ContattiClienteComponent } from '../contatti-cliente/contatti-cliente.component';
import { EliminaClienteComponent } from '../elimina-cliente/elimina-cliente.component';
import { EliminaImmagineClienteComponent } from '../elimina-immagine-cliente/elimina-immagine-cliente.component';
import { AggiungiGruppoComponent } from 'src/app/gruppi/aggiungi-gruppo/aggiungi-gruppo.component';
import { environment } from 'src/environments/environment';
import { AggiungiTesseramentoComponent } from 'src/app/tesseramenti/aggiungi-tesseramento/aggiungi-tesseramento.component';

@Component({
  selector: 'app-dettagli-cliente',
  templateUrl: './dettagli-cliente.component.html',
  styleUrls: ['./dettagli-cliente.component.css']
})
export class DettagliClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private datePipe: DatePipe) { }

  id!: number;

  cliente?: Cliente;
  tesseramenti: Tesseramento[] = [];
  contatti: Contatto[] = [];
  gruppi: Gruppo[] = []
  movimenti: Movimenti[] = [];

  skipass_open: string = '';
  skicard_vialattea: string = '';
  info: { skipass_open: boolean, skicard_vialattea: boolean } = { skipass_open: false, skicard_vialattea: false };

  immagine: string = '';

  show_all_gruppi: boolean = false;
  show_all_contatti: boolean = false;
  show_all_tesseramenti: boolean = false;  
  show_all_movimenti: boolean = false;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getData();
    });
  }

  getData() {

    // Recupero i dati del cliente in base all'id
    this.apiService.getCliente(this.id).subscribe({
      next: (data) => this.cliente = data,
      error: (err) => this.router.navigate(['/clienti']),
      complete: () => {
        // Recupero l'immagine profilo del cliente
        this.apiService.getImmagine(this.cliente?.immagine!).subscribe({
          next: (data) => this.immagine = data.file,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {
            // Recupero i tesseramenti del cliente
            this.apiService.getTesseramentiCliente(this.id).subscribe({
              next: (data) => this.tesseramenti = data,
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {

                // Ciclo tra i tesseramenti 
                for (let tesseramento of this.tesseramenti) {

                  // Se il cliente ha una skicard vialattea mostro il codice
                  if (tesseramento.id_prodotto == 1) {
                    this.skicard_vialattea = tesseramento.codice_tesseramento || '';
                    this.info.skicard_vialattea = true;

                    // Se il cliente ha una skipass open o il rinnovo mostro il codice
                  } else if (tesseramento.id_prodotto == 2 || tesseramento.id_prodotto == 24) {
                    this.skipass_open = tesseramento.codice_tesseramento || '';
                    this.info.skipass_open = true;
                  }
                }

                // Recupero i gruppi del cliente
                this.apiService.getGruppiCliente(this.id).subscribe({
                  next: (data) => this.gruppi = data,
                  error: (err) => this.apiService.Error(err.error.message),
                  complete: () => {
                    // Recupero i movimenti del cliente
                    this.apiService.getMovimentiCliente(this.id).subscribe({
                      next: (data) => this.movimenti = data,
                      error: (err) => this.apiService.Error(err.error.message),
                      complete: () => {
                        // Recupero i contatti del cliente
                        this.apiService.setContatti(this.id);
                        this.apiService.contattiChanged.subscribe({
                          next: (data) => this.contatti = data,
                          error: (err) => this.apiService.Error(err.error.message),
                          complete: () => { }
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    })

  }

  onIndietro() {
    this.router.navigate(['/clienti']);
  }

  onContatti() {
    this.dialog.closeAll();
    this.dialog.open(ContattiClienteComponent, { data: { id: this.id }, autoFocus: false, width: '1000px' });
  }

  onGruppi() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(AggiungiGruppoComponent, { data: { cliente: this.cliente }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onModifica() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ModificaClienteComponent, { data: { cliente: this.cliente, skipass_open: this.skipass_open, skicard_vialattea: this.skicard_vialattea, info: this.info }, autoFocus: false, width: '1000px' })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }


  onModificaContatto(id: number) {
    this.dialog.closeAll();
    this.dialog.open(ModificaContattoClienteComponent, { data: { id_cliente: this.id, id_contatto: id }, autoFocus: false, width: '1000px' })
  }

  onOrder() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiTesseramentoComponent, { data: { cliente: this.cliente }, autoFocus: false, width: '1000px' })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaClienteComponent, { data: { id: this.id }, autoFocus: false, width: '750px' })
  }

  onImpersonate() {

    this.apiService.getTokenImpersona(this.cliente!.id).subscribe({
      next: (data) => window.open(environment.shopURL + "impersona?token=" + data.token, "_blank"),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })
  }

  onEliminaImmagine() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(EliminaImmagineClienteComponent, { data: { id: this.id, immagine: this.cliente?.immagine }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onDownload() {
    if (this.immagine.length > 0) {
      this.apiService.getImmagineCliente(this.cliente!.id).subscribe({
        next: (data) => {
          let downloadURL = window.URL.createObjectURL(data);
          saveAs(downloadURL, 'img_cliente_' + this.id + '.jpg');
        },
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => { }
      })
    }
  }

  exportData() {

    let dati: any;

    this.apiService.getDatiCliente(this.cliente!.id).subscribe({
      next: (data) => dati = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet([{}]);

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['CLIENTE']
        ], { origin: 0 });

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['', ['id'], ['nome'], ['cognome'], ['data di nascita'], ['comune di nascita'], ['provincia di nascita'], ['stato di nascita'], ['indirizzo di residenza'], ['comune di residenza'], ['provincia di residenza'], ['stato di residenza'], ['codice fiscale'], ['telefono'], ['email'], ['data creazione'], ['data aggiornamento']]
        ], { origin: 1 },);

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['', dati[0]['cliente']['id'], dati[0]['cliente']['nome'], dati[0]['cliente']['cognome'], this.datePipe.transform(dati[0]['cliente']['data_nascita'], 'dd/MM/yyyy'), dati[0]['cliente']['comune_nascita'], dati[0]['cliente']['provincia_nascita'], dati[0]['cliente']['nazione_nascita'], dati[0]['cliente']['indirizzo'], dati[0]['cliente']['comune'], dati[0]['cliente']['provincia'], dati[0]['cliente']['nazione'], dati[0]['cliente']['codice_fiscale'], dati[0]['cliente']['telefono'], dati[0]['cliente']['email'], this.datePipe.transform(dati[0]['cliente']['data_creazione'], 'dd/MM/yyyy'), this.datePipe.transform(dati[0]['cliente']['data_aggiornamento'], 'dd/MM/yyyy')]
        ], { origin: 2 },);

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['TESSERAMENTI']
        ], { origin: 4 });

        let riga: number = 5;

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['', ["id"], ["nome"], ["codice"], ["prezzo"], ["categoria"], ["inizio validità"], ["fine validità"]]
        ], { origin: riga });



        for (let i = 0; i < dati[0]['tesseramenti'].length; i++) {
          riga = riga + 1;
          XLSX.utils.sheet_add_aoa(workSheet, [
            ['', dati[0]['tesseramenti'][i]["id"], dati[0]['tesseramenti'][i]["nome"], dati[0]['tesseramenti'][i]["codice_tesseramento"], dati[0]['tesseramenti'][i]["prezzo"], dati[0]['tesseramenti'][i]["categoria"], dati[0]['tesseramenti'][i]["inizio_validita"], dati[0]['tesseramenti'][i]["fine_validita"]]
          ], { origin: riga });
        }

        XLSX.utils.sheet_add_aoa(workSheet, [
          ['GRUPPI']
        ], { origin: riga + 2 });


        XLSX.utils.sheet_add_aoa(workSheet, [
          ['', ["id"], ["nome"], ["descrizione"]]
        ], { origin: riga + 3 });

        riga = riga + 3;
        for (let j = 0; j < dati[0]['gruppi'].length; j++) {
          riga = riga + 1;
          XLSX.utils.sheet_add_aoa(workSheet, [
            ['', dati[0]['gruppi'][j]["id"], dati[0]['gruppi'][j]["nome"], dati[0]['gruppi'][j]["descrizione"]]
          ], { origin: riga });
        }

        workSheet["!cols"] = [{ wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Cliente'); // add the worksheet to the book
        XLSX.writeFile(workBook, 'cliente.xlsx'); // initiate a file download in browser
      }
    })
  }

}
