import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Categoria } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-categoria',
  templateUrl: './modifica-categoria.component.html',
  styleUrls: ['./modifica-categoria.component.css']
})
export class ModificaCategoriaComponent implements OnInit {


  constructor(private dialogRef: MatDialogRef<ModificaCategoriaComponent>, @Inject(MAT_DIALOG_DATA) public data: {id: number} , private apiService: ApiService, private router: Router) { }

  formCategoria!: FormGroup;

  categorie: Categoria[] = [];
  categoria?: Categoria;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica della categoria'}
    ],    
  }

  ngOnInit(): void {

    this.formCategoria = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null),
      posizione: new FormControl(null)
    })

    this.apiService.getCategorie().subscribe({
      next: (data) => this.categorie = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.apiService.getCategoria(this.data.id).subscribe({
          next: (data) => this.categoria = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {
            this.formCategoria.setValue({
              nome: this.categoria?.nome,
              descrizione: this.categoria?.descrizione,
              posizione: this.categoria?.posizione
            })
          }
        })
      }
    })    

  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {

    let categoria = {
      nome: this.formCategoria.get('nome')?.value,
      descrizione:this.formCategoria.get('descrizione')?.value,
      posizione:this.formCategoria.get('posizione')?.value || "ultimo",
    }

    this.apiService.updateCategoria(this.data.id, categoria).subscribe({
      next: (data) => this.dialogRef.close(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    }) 

  }

}
