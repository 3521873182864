import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-categoria',
  templateUrl: './elimina-categoria.component.html',
  styleUrls: ['./elimina-categoria.component.css']
})
export class EliminaCategoriaComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EliminaCategoriaComponent>, @Inject(MAT_DIALOG_DATA) public data : {id: number}, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(false)
  }

  onElimina() {

    this.apiService.deleteCategoria(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/categorie'])      
    })

  }  

}
