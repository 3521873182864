import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AuthUser, Movimenti } from 'src/app/shared/interfacce';
import { AggiungiMovimentoComponent } from '../aggiungi-movimento/aggiungi-movimento.component';
import * as XLSX from 'xlsx';
import { EsportazioneMovimentiComponent } from '../esportazione-movimenti/esportazione-movimenti.component';
import { ReportMovimentiComponent } from '../report-movimenti/report-movimenti.component';

@Component({
  selector: 'app-movimenti-metodi',
  templateUrl: './movimenti-metodi.component.html',
  styleUrls: ['./movimenti-metodi.component.css']
})
export class MovimentiMetodiComponent implements OnInit {

  constructor(private apiService: ApiService, private location: Location, private dialog: MatDialog, private router: Router) { }

  movimenti: Movimenti[] = [];

  carica: boolean = true;

  descrizioneFilter: any = '';

  ngOnInit(): void {

    // Recupero i movimenti degli altri metodi
    this.apiService.setMovimenti();
    this.apiService.movimentiChanged.subscribe({
      next: (data) => {
        this.movimenti = data.filter((movimento: Movimenti) => movimento.metodo?.toLowerCase().indexOf('contanti') < 0);
        this.carica = false
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamentoDescrizione(a: Movimenti, b: Movimenti) {
    return a.descrizione.localeCompare(b.descrizione)
  }

  onOrdinamentoMetodo(a: Movimenti, b: Movimenti) {
    return a.metodo.localeCompare(b.metodo)
  }

  onOrdinamentoPagato(a: Movimenti, b: Movimenti) {
    return a.pagato - b.pagato;
  }

  onOrdinamentoResiduo(a: Movimenti, b: Movimenti) {
    return a.residuo - b.residuo;
  }

  onOrdinamentoTotale(a: Movimenti, b: Movimenti) {
    return a.totale - b.totale;
  }

  onOrdinamentoData(a: Movimenti, b: Movimenti) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiMovimentoComponent, { data: { tipo: 'altri-metodi' }, autoFocus: false, width: '1000px' });
  }

  onExportGiornalieri() {

    this.apiService.getMovimentiGiornalieri('altri-metodi').subscribe({
      next: (data) => {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(data);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 30 }, { wch: 40 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Movimenti');
        XLSX.writeFile(workBook, 'movimenti_giornalieri.xlsx');
      },
      error: (err) => this.apiService.Error(err.error.message),
    })
  }

  onExport() {
    this.dialog.closeAll();
    this.dialog.open(EsportazioneMovimentiComponent, { data: { tipo: 'altri-metodi' }, autoFocus: false, width: '1000px' });
  }

  onReport() {
    this.dialog.closeAll();
    this.dialog.open(ReportMovimentiComponent, { data: { tipo: 'altri-metodi' }, autoFocus: false, width: '1000px' });
  }

}
