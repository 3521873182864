import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stampa-dettaglio-ordine',
  templateUrl: './stampa-dettaglio-ordine.component.html',
  styleUrls: ['./stampa-dettaglio-ordine.component.css']
})
export class StampaDettaglioOrdineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
