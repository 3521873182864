import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Contatto } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-contatti-cliente',
  templateUrl: './contatti-cliente.component.html',
  styleUrls: ['./contatti-cliente.component.css']
})
export class ContattiClienteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ContattiClienteComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService) { }

  formContatto!: FormGroup;

  contatti: Contatto[] = [];
  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = [];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del contatto' }
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la creazione del contatto' }
    ],
    'email': [
      { type: 'required', message: "Inserire un'email per procedere con la creazione del contatto" },
      { type: 'email', message: "Inserire un'email valida per procedere con la creazione del contatto" }
    ],
    'telefono': [
      { type: 'required', message: 'Inserire un numero di telefono per procedere con la creazione del contatto' },
      { type: 'pattern', message: 'Inserire un numero di telefono valido, di 9 o 10 cifre' }
    ],
    'parentela': [
      { type: 'required', message: 'Inserire un grado di parentela per procedere con la creazione del contatto' }
    ],
  }

  ngOnInit(): void {

    this.formContatto = new FormGroup({
      cliente: new FormControl(null),
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.email),
      telefono: new FormControl(null, Validators.pattern('^(?=[0-9]*$)(?:.{9}|.{10})$')),
      parentela: new FormControl(null, Validators.required)
    })

    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.filteredClienti = this.clienti.slice(0, 10)
    })
  }

  mostraValore(cliente: Cliente): string {
    var nome_cliente = cliente && cliente.nome ? cliente.cognome.trim() + ' ' + cliente.nome.trim() : '';
    return nome_cliente;
  }

  onSeleziona(ricerca: any) {    
    this.filteredClienti = this.filtra(ricerca).slice(0,10)
  }

  filtra(name: string): Cliente[] {
    const filterValue = name.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onEvent(e: Cliente) {
    if (e.id) {
      this.formContatto.patchValue({
        nome: e.nome,
        cognome: e.cognome,
        email: e.email,
        telefono: e.telefono
      })
    }
  }

  onClose() {
    this.dialogRef.close(true)
  }

  onSubmit() {
    let contatto = {
      id_cliente: this.data.id,
      nome: this.formContatto.get('nome')?.value,
      cognome: this.formContatto.get('cognome')?.value,
      parentela: this.formContatto.get('parentela')?.value,
      email: this.formContatto.get('email')?.value,
      telefono: this.formContatto.get('telefono')?.value
    }

    this.apiService.addContatto(contatto).subscribe({
      next: (data) => this.apiService.setContatti(this.data.id),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

}
