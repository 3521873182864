import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-immagine-cliente',
  templateUrl: './elimina-immagine-cliente.component.html',
  styleUrls: ['./elimina-immagine-cliente.component.css']
})
export class EliminaImmagineClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EliminaImmagineClienteComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number, immagine: string }) { }

  ngOnInit(): void {
  }

  eliminaImmagine() {

    this.apiService.deleteImmagineCliente(this.data.id, this.data.immagine).subscribe({
      next: (data) => {},
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }

}
