import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Ordine } from '../shared/interfacce';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { EsportazioneStoricoOrdineComponent } from './esportazione-storico-ordine/esportazione-storico-ordine.component';
import { AggiungiTesseramentoComponent } from '../tesseramenti/aggiungi-tesseramento/aggiungi-tesseramento.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-storico-ordini',
  templateUrl: './storico-ordini.component.html',
  styleUrls: ['./storico-ordini.component.css']
})
export class StoricoOrdiniComponent implements OnInit {

  constructor(private apiService: ApiService, private location: Location, private dialog: MatDialog) { }

  ordini: Ordine[] = [];

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  carica: boolean = true;
  loading: boolean = true;

  clienteFilter: any = '';
  prodottoFilter: any = '';
  statoFilter: any = '';

  filtri: { pageIndex: number, pageSize: number, cliente: string, prodotto: string, stato: string } = { pageIndex: 1, pageSize: 10, cliente: '', prodotto: '', stato: 'valido' };

  ngOnInit(): void {

    this.loading = true;

    // Recupero gli ordini
    this.apiService.setOrdini(this.pageIndex, 10, 'id_ordine', 'descend', this.clienteFilter, this.prodottoFilter, this.statoFilter);
    this.apiService.ordineChanged.subscribe({
      next: (data) => {
        this.ordini = data;
        this.totale = this.apiService.TotaleOrdini;
        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  loadDataFromServer(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setOrdini(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.prodottoFilter, this.statoFilter);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, prodotto: this.prodottoFilter, stato: this.statoFilter };

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'id_ordine', value: 'descend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.prodottoFilter != this.filtri.prodotto || this.statoFilter != this.filtri.stato)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.prodottoFilter != this.filtri.prodotto || this.statoFilter != this.filtri.stato)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiTesseramentoComponent, { autoFocus: false, width: '1000px' })
  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamento(a: Ordine, b: Ordine) {
    return 1 - 1;
  }

  onExportGiornalieri() {

    // Recupero gli ordini giornalieri
    this.apiService.getOrdiniGiornalieri().subscribe({
      next: (data) => {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(data);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Ordini');
        XLSX.writeFile(workBook, 'ordini_giornalieri.xlsx');
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onExport() {
    this.dialog.closeAll();
    this.dialog.open(EsportazioneStoricoOrdineComponent, { autoFocus: false, width: '1000px' });
  }

}
