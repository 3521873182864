import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { FasceProdotti } from 'src/app/shared/interfacce';
import { EliminaFascePrezziProdottoComponent } from '../elimina-fasce-prezzi-prodotto/elimina-fasce-prezzi-prodotto.component';

@Component({
  selector: 'app-fasce-prezzi-prodotto',
  templateUrl: './fasce-prezzi-prodotto.component.html',
  styleUrls: ['./fasce-prezzi-prodotto.component.css']
})
export class FascePrezziProdottoComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<FascePrezziProdottoComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { id_prodotto?: number }) { }

  formFasce!: FormGroup;

  fasce: FasceProdotti[] = [];

  esito: boolean = false;

  error_messages = {
    'prezzo': [
      { type: 'required', message: 'Inserire un prezzo per procedere con la creazione del prodotto' },
      { type: 'pattern', message: 'Inserire un prezzo valido' }
    ],
    'eta_min': [
      { type: 'min', message: 'Inserire come minimo 0 anni di età' },
      { type: 'max', message: 'Inserire come massimo 119 anni di età' },
      { type: 'required', message: "Inserire un'età minima per procedere con la creazione della fascia di prezzo" },
    ],
    'eta_max': [
      { type: 'min', message: 'Inserire come minimo 1 anni di età' },
      { type: 'max', message: 'Inserire come massimo 120 anni di età' },
      { type: 'required', message: "Inserire un'età massima per procedere con la creazione della fascia di prezzo" },
    ],
  }

  ngOnInit(): void {

    // Formo il form 
    this.formFasce = new FormGroup({
      prezzo: new FormControl(null, Validators.required),
      eta_max: new FormControl(null, Validators.required),
      eta_min: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    // Recupero le fasce
    this.getFasce();
  }

  onClose() {
    this.dialogRef.close(false)
  }

  getFasce() {

    // Se è stato passato l'id del prodotto procedo
    if (this.data?.id_prodotto) {

      // Recupero le fasce salvate del prodotto
      this.apiService.setFasceProdotti(this.data.id_prodotto);
      this.apiService.fasceProdottiChanged.subscribe({
        next: (data) => this.fasce = data,
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => { }
      })

    } else {

      // Recupero le fasce nel session
      let fasce_local = JSON.parse(sessionStorage.getItem('fasce') || '[]');
      this.fasce = fasce_local;
    }

    // Ordino le fasce per età
    this.fasce.sort(function (a, b) {
      return a.eta_max - b.eta_max;
    });
  }

  onElimina(id: number, index: number) {

    // Se è stato passato un progetto procedo
    if (this.data?.id_prodotto) {

      // Apro il modal per l'eliminazione della fascia
      let dialogRef = this.dialog.open(EliminaFascePrezziProdottoComponent, { data: { id: id }, autoFocus: false, width: '750px' });
      dialogRef?.afterClosed().subscribe((esito: boolean) => {
        // Se c'è stato esito positivo recupero le fasce
        if (esito) {
          this.getFasce();
        }
      })
    } else {

      // Se non è stato passato il prodotto, semplicemente rimuovo la fascia dal session storage
      this.fasce.splice(index, 1);
      sessionStorage.setItem('fasce', JSON.stringify(this.fasce));
      this.getFasce();
    }
  }

  onSubmit() {

    // Formo l'oggetto per la fascia
    let oggetto = {
      eta_min: this.formFasce.get('eta_min')?.value,
      eta_max: this.formFasce.get('eta_max')?.value,
      prezzo: this.formFasce.get('prezzo')?.value,
      descrizione: this.formFasce.get('descrizione')?.value,
      id_prodotto: 0
    }

    // Procedo se l'età minore inserita è minore rispetto all'età massima
    if (oggetto.eta_min < oggetto.eta_max) {

      // Se il prodotto è già presente
      if (this.data?.id_prodotto) {

        // Aggiungo l'id del prodotto all'ogetto
        oggetto.id_prodotto = this.data.id_prodotto;

        // Aggiungo la fascia al db        
        this.apiService.addFasciaProdotti(oggetto).subscribe({
          next: (data) => this.apiService.setFasceProdotti(this.data.id_prodotto!),
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Resetto il form
            this.formFasce.reset();
            this.formFasce.markAsUntouched();
            this.formFasce.markAsPristine();
  
            this.formFasce.get('eta_min')?.updateValueAndValidity();
            this.formFasce.get('eta_max')?.updateValueAndValidity();
            this.formFasce.get('prezzo')?.updateValueAndValidity();
            this.formFasce.get('descrizione')?.updateValueAndValidity();
          }
        })
      } else {

        // Recupero le fasce salvate in locale
        let fasce = JSON.parse(localStorage.getItem('fasce') || '[]');

        // Se ci sono delle fasce già salvate procedo
        if (fasce.length > 0) {

          // Ciclo tra le fasce
          for (let fascia of fasce) {

            // Se l'età inserita nell'oggetto è compresa tra un'altra fascia non la inserisco                                                
            if (!(oggetto.eta_min >= fascia.eta_min && oggetto.eta_min <= fascia.eta_max) && !(oggetto.eta_max >= fascia.eta_min && oggetto.eta_max <= fascia.eta_max)) {
              fasce.push(oggetto);
              this.esito = true;
              break;
            }

          }
        } else {
          fasce.push(oggetto);
          this.esito = true;
        }

        // Salvo le fasce
        sessionStorage.setItem('fasce', JSON.stringify(fasce));

        // Se ho esito positivo resetto il form                        
        if (this.esito) {
          this.formFasce.reset();
          this.formFasce.markAsUntouched();
          this.formFasce.markAsPristine();

          this.formFasce.get('eta_min')?.updateValueAndValidity();
          this.formFasce.get('eta_max')?.updateValueAndValidity();
          this.formFasce.get('prezzo')?.updateValueAndValidity();
          this.formFasce.get('descrizione')?.updateValueAndValidity();

          this.getFasce();

        } else {
          this.apiService.Error("L'età inserita è in contrasto con un altra fascia già inserita");
        }
      }

    } else {
      this.apiService.Error("L'età massima inserita è minore rispetto all'età minima inserita");
    }

  }

}
