import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Prodotto, Promozione } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-promozione',
  templateUrl: './modifica-promozione.component.html',
  styleUrls: ['./modifica-promozione.component.css']
})
export class ModificaPromozioneComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<ModificaPromozioneComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private datePipe: DatePipe) { }

  formPromozione!: FormGroup;

  prodotti: Prodotto[] = [];
  promozione?: Promozione;

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica della promozione' },
      { type: 'minlength', message: 'Inserire almeno 5 caratteri' }
    ],
    'descrizione': [
      { type: 'required', message: 'Inserire un descrizione per procedere con la modifica della promozione' }
    ],
    'prodotto': [
      { type: 'required', message: 'Inserire un prodotto per procedere con la modifica della promozione' }
    ],
    'percentuale': [
      { type: 'required', message: 'Inserire un percentuale per procedere con la modifica della promozione' }
    ],
    'giornaliero': [
      { type: 'required', message: 'Inserire un giornaliero per procedere con la modifica della promozione' }
    ],
    'sconto': [
      { type: 'required', message: 'Inserire un sconto per procedere con la modifica della promozione' },
      { type: 'pattern', message: 'Inserire un sconto valido' }
    ],
    'inizio_validita': [
      { type: 'required', message: 'Inserire un inizio di validita per procedere con la modifica della promozione' },
      { type: 'matDatepickerFilter', message: 'Inserire un inizio validita valido' }
    ],
    'fine_validita': [
      { type: 'required', message: 'Inserire una fine di validita per procedere con la modifica della promozione' },
      { type: 'matDatepickerFilter', message: 'Inserire un inizio validita valido' }
    ],
    'giorni': [
      { type: 'required', message: 'Inserire dei giorni per procedere con la modifica della promozione' }
    ],
    'inizio_orario': [
      { type: 'required', message: 'Inserire un inizio_orario per procedere con la modifica della promozione' }
    ],
    'fine_orario': [
      { type: 'required', message: 'Inserire un fine_orario per procedere con la modifica della promozione' }
    ],
    'eta_min': [
      { type: 'min', message: 'Inserire come minimo 0 anni di età' },
      { type: 'max', message: 'Inserire come massimo 119 anni di età' },
      { type: 'required', message: "Inserire un'età minima per procedere con la creazione della promozione" },
    ],
    'eta_max': [
      { type: 'min', message: 'Inserire come minimo 1 anni di età' },
      { type: 'max', message: 'Inserire come massimo 120 anni di età' },
      { type: 'required', message: "Inserire un'età massima per procedere con la creazione della promozione" },
    ],
  }


  ngOnInit(): void {

    // Formo i controlli per il form
    this.formPromozione = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null),
      prodotto: new FormControl(null, Validators.required),
      eta_max: new FormControl(null),
      eta_min: new FormControl(null),
      percentuale: new FormControl(null),
      giornaliero: new FormControl(null),
      sconto: new FormControl(null, [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
      inizio_validita: new FormControl(null, Validators.required),
      fine_validita: new FormControl(null, Validators.required),
      giorni: new FormControl(null),
      inizio_orario: new FormControl(null),
      fine_orario: new FormControl(null)
    })

    // Recupero i prodotti
    this.apiService.getProdotti().subscribe({
      next: (data) => this.prodotti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        // Recupero la promozione in base all'id
        this.apiService.getPromozione(this.data.id).subscribe({
          next: (data) => this.promozione = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Formo le date di inizio e fine validità per il form
            let inizio_validità = this.promozione?.giornaliero ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : this.datePipe.transform(this.promozione?.inizio_validita, 'yyyy-MM-dd');
            let fine_validità = this.promozione?.giornaliero ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : this.datePipe.transform(this.promozione?.fine_validita, 'yyyy-MM-dd');

            // Setto i valori della promozione nel form
            this.formPromozione.setValue({
              nome: this.promozione?.nome,
              descrizione: this.promozione?.descrizione,
              prodotto: this.promozione?.id_prodotto,
              eta_min: this.promozione?.eta_min,
              eta_max: this.promozione?.eta_max,
              percentuale: this.promozione?.percentuale ? true : false,
              giornaliero: this.promozione?.giornaliero ? true : false,
              sconto: this.promozione?.sconto,
              inizio_validita: this.promozione?.inizio_validita,
              fine_validita: this.promozione?.fine_validita,
              giorni: this.promozione?.giornaliero ? JSON.parse(this.promozione.giorni) : '',
              inizio_orario: this.promozione?.inizio_orario ? new Date(inizio_validità + ' ' + this.promozione.inizio_orario) : this.promozione?.inizio_orario,
              fine_orario: this.promozione?.fine_orario ? new Date(fine_validità + ' ' + this.promozione.fine_orario) : this.promozione?.fine_orario
            })
          }
        })
      }
    })

  }

  onGiornalieri(giornalieri: boolean) {

    // Rimuovo i validator per azzerare la situazione
    this.formPromozione.get('inizio_validita')?.clearValidators();
    this.formPromozione.get('fine_validita')?.clearValidators();
    this.formPromozione.get('giorni')?.clearValidators();

    // Se è stato selezionato come giornaliero procedo
    if (giornalieri) {

      // Setto come obbligatorio selezionare almeno un giorno nel form
      this.formPromozione.get('giorni')?.setValidators(Validators.required);

    } else {

      // Setto come obbligatorio inserire una data di inizio e fine validità
      this.formPromozione.get('inizio_validita')?.setValidators(Validators.required);
      this.formPromozione.get('fine_validita')?.setValidators(Validators.required);

    }

    // Aggiorno la validità dei campi nel form
    this.formPromozione.get('inizio_validita')?.updateValueAndValidity();
    this.formPromozione.get('fine_validita')?.updateValueAndValidity();
    this.formPromozione.get('giorni')?.updateValueAndValidity();

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let promozione = {
      nome: this.formPromozione.get('nome')?.value,
      descrizione: this.formPromozione.get('descrizione')?.value,
      id_prodotto: this.formPromozione.get('prodotto')?.value,
      eta_min: this.formPromozione.get('eta_min')?.value || 1,
      eta_max: this.formPromozione.get('eta_max')?.value || 120,
      percentuale: this.formPromozione.get('percentuale')?.value ? true : false,
      giornaliero: this.formPromozione.get('giornaliero')?.value ? true : false,
      sconto: this.formPromozione.get('sconto')?.value,
      inizio_validita: this.formPromozione.get('giornaliero')?.value ? null : this.datePipe.transform(this.formPromozione.get('inizio_validita')?.value, 'yyyy-MM-dd'),
      fine_validita: this.formPromozione.get('giornaliero')?.value ? null : this.datePipe.transform(this.formPromozione.get('fine_validita')?.value, 'yyyy-MM-dd'),
      giorni: this.formPromozione.get('giornaliero')?.value ? JSON.stringify(this.formPromozione.get('giorni')?.value) : null,
      inizio_orario: this.datePipe.transform(this.formPromozione.get('inizio_orario')?.value, 'HH:mm'),
      fine_orario: this.datePipe.transform(this.formPromozione.get('fine_orario')?.value, 'HH:mm')
    }

    // Effettuo l'update della promozione con i nuovi dati
    this.apiService.updatePromozione(this.data.id, promozione).subscribe({
      next: (data) => this.apiService.setPromozioni(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })
  }

  onClose() {
    this.dialogRef.close(false);
  }

}
