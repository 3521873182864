import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Prodotto, Tesseramento, Carrello, CheckOut, PuntoRitiro } from 'src/app/shared/interfacce';
import { ModificaProdottoTesseramentoComponent } from '../modifica-prodotto-tesseramento/modifica-prodotto-tesseramento.component';
import { differenceInCalendarDays } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aggiungi-prodotti-tesseramento',
  templateUrl: './aggiungi-prodotti-tesseramento.component.html',
  styleUrls: ['./aggiungi-prodotti-tesseramento.component.css']
})
export class AggiungiProdottiTesseramentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiProdottiTesseramentoComponent>, @Inject(MAT_DIALOG_DATA) public data: { clienti: Cliente[] }, private apiService: ApiService, private datePipe: DatePipe, private dialog: MatDialog) { }

  @ViewChild('listaProdotti', { static: false }) listaProdotti!: ElementRef;

  totale: number = 0;
  totale_carrello: number = 0;
  eta: number = 0;
  i: number = 0;

  punti_ritiro: PuntoRitiro[] = [];
  prodotti_selezionati: Prodotto[] = [];
  prodotti: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  cliente!: Cliente;

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  disable_all: boolean = true;
  disable_skipass: boolean = true;
  informazione: boolean = false;
  caricamento: boolean = true;

  informazioni: { ritiro: boolean, giornalieri: Prodotto[], giornalieri_specifici: Prodotto[] } = { ritiro: false, giornalieri: [], giornalieri_specifici: [] }

  carrello: Carrello[] = [];
  checkout: CheckOut = { note: '', carrello: this.carrello, totale: 0 };

  formInfo!: FormGroup;

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formInfo = new FormGroup({
      ritiro: new FormControl(null)
    })

    this.getData();
  }

  getData() {

    this.caricamento = true;

    this.totale = 0;
    this.prodotti_selezionati = [];

    // Recupero il cliente che sta facendo l'ordine
    this.cliente = this.data.clienti[this.i];

    // Calcolo l'età del cliente
    let differenza = Math.abs(Date.now() - new Date(this.cliente.data_nascita).getTime());
    this.eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

    // Recupero i punti di ritiro
    this.apiService.getPuntiRitiro().subscribe({
      next: (data) => this.punti_ritiro = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Recupero i prodotti
        this.apiService.getProdottiEta(this.cliente.id, this.eta).subscribe({
          next: (data) => this.prodotti = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Recupero i possibili tesseramenti del cliente
            this.apiService.getTesseramentiCliente(this.cliente.id).subscribe({
              next: (data) => this.tesseramenti = data,
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {

                // creo il record nel carrello per il membro selezionato se non era presente
                const alreadyExists = this.carrello.find((carrello) => carrello.id_cliente === this.cliente.id);
                if (!alreadyExists) { this.carrello.push({ totale: 0, prodotti: [], id_cliente: this.cliente.id, nome: this.cliente.nome, cognome: this.cliente.cognome }); }

                // Recupero i prezzi corretti per i prodotti
                this.getTesseramenti();
                this.getCarrello(this.cliente.id);

                this.caricamento = false;

                // Ordino i prodotti in base se sono abilitati o no
                this.prodotti.sort(function (a, b) { return (b.disabilitato === a.disabilitato) ? 0 : b.disabilitato ? -1 : 1; });

              }
            })
          }
        })

      }
    })    
  }

  getTesseramenti() {

    this.disable_all = true;
    this.disable_skipass = true;

    // ciclo tra i prodotti
    for (let prodotto of this.prodotti) {

      // Ciclo tra i tesseramenti del cliente
      for (let tesseramento of this.tesseramenti) {

        let tesseramento_validita = this.dateDiff(tesseramento.inizio_validita, tesseramento.fine_validita);

        // Se il tesseramento è valido procedo
        if (tesseramento_validita != 0) {

          // se trovo il match disabilito il prodotto
          if (tesseramento.id_prodotto == prodotto.id) {

            prodotto.disabilitato = true;
            prodotto.stato = '- (ACQUISTATO E VALIDO)'
            prodotto.attivo = true;

            // se c'è un prodotto obbligatorio nei tesseramenti attivi abilito il resto dei prodotti
            if (prodotto.obbligatorio) {
              this.disable_all = false;
            }

            // se c'è la ski card della vialattea nei tesseramenti attivi abilito i giornalieri
            if (prodotto.id == 1) {
              this.disable_skipass = false;
            }

          }
        }

      }

    }
  }

  dateDiff(inizio_validita: Date, fine_validita: Date) {
    let todayDate = new Date();
    let fineDate = new Date(fine_validita);
    let inizioDate = new Date(inizio_validita);

    inizioDate.setDate(inizioDate.getDate());
    let diffInizio = todayDate.getTime() - inizioDate.getTime();
    let diffGiorniInizio = Math.floor(diffInizio / (1000 * 3600 * 24));

    fineDate.setDate(fineDate.getDate());
    let diffFine = todayDate.getTime() - fineDate.getTime();
    let diffGiorniFine = Math.floor(diffFine / (1000 * 3600 * 24));

    return diffGiorniInizio < 0 ? 2 : diffGiorniInizio >= 0 && diffGiorniFine <= 0 ? 1 : 0;
  }

  getCarrello(id_cliente: number) {

    // se c'è il carrello per il cliente procedo
    if (this.carrello.length > 0) {

      // recupero il carrello del cliente
      let cart = this.carrello.filter((carrello: Carrello) => carrello.id_cliente == id_cliente)[0];

      if (cart) {

        // ciclo per i prodotti nel carrello
        for (let prodotto_carrello of cart.prodotti) {

          // ciclo per i prodotti
          for (let prodotto of this.prodotti) {

            // se trovo il match lo aggiungo ai proodotti selezionati
            if (prodotto_carrello.id == prodotto.id) {
              this.onAdd(prodotto)
            }
          }

        }
      }
    }

  }

  getInfo() {

    this.informazioni.giornalieri = [];
    this.informazioni.giornalieri_specifici = [];

    for (const controlName in this.formInfo.controls) {
      if (controlName != 'ritiro') {
        this.formInfo.removeControl(controlName);
      }
    }

    //ciclo nel carrello
    for (let carrello of this.carrello) {

      //ciclo nei prodotti
      for (let prodotto of carrello.prodotti) {

        //se un prodotto è delle categoria della Vialattea o SkipassOpen
        if (prodotto.informativo) {

          // setto true il pannello informazioni e rendo obbligatoria la scelta del punto di ritiro
          this.informazione = true;
          this.informazioni.ritiro = true;

          this.formInfo.get('ritiro')?.setValidators(Validators.required);
          this.formInfo.get('ritiro')?.updateValueAndValidity();

        }

        // se è un prodotto giornaliero 
        if (prodotto.giornaliero) {

          // mostro il pannello informazione
          this.informazione = true;

          // parso i giorni se non sono già stati parsati
          try {
            prodotto.giorni = JSON.parse(prodotto.giorni);
          } catch {
            prodotto.giorni = prodotto.giorni;
          }

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazioni.giornalieri.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazioni.giornalieri.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInfo.addControl('giornaliero_' + prodotto.id, new FormControl('', Validators.required));
        }

        // se è un prodotto giornaliero 
        if (prodotto.date_specifiche) {

          // mostro il pannello informazione
          this.informazione = true;

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazioni.giornalieri_specifici.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazioni.giornalieri_specifici.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInfo.addControl('giornaliero_specifico_' + prodotto.id, new FormControl('', Validators.required));
        }
      }

    }
  }

  checkCarrello() {

    // recupero il carrello del cliente
    let carrello = this.carrello.filter((carrello: Carrello) => carrello.id_cliente == this.cliente.id)[0];

    return carrello?.prodotti.length;
  }

  onAdd(prodotto: Prodotto) {

    prodotto.selezionato = true;

    // recupero il carrello del cliente
    let carrello = this.carrello.filter((carrello: Carrello) => carrello.id_cliente == this.cliente.id)[0];

    // controllo se esiste già il prodotto nel carrello
    const alreadyExists = carrello.prodotti.find((prod) => prod.id === prodotto.id);
    if (!alreadyExists) {
      carrello.prodotti.push(prodotto);
    }

    // abilito tutti i prodotti se era obbligatorio
    if (prodotto.obbligatorio)
      this.disable_all = false;

    // se c'è la ski card della vialattea nei tesseramenti attivi abilito i giornalieri
    if (prodotto.id == 1)
      this.disable_skipass = false;

    this.totale = 0;
    // ciclo per i prodotti selzezionati
    for (let prodotto_carrello of carrello.prodotti) {
      this.totale += prodotto_carrello.prezzo;
    }

    carrello.totale = this.totale;

    // Utilizza il metodo reduce per sommare il campo "valore" di ciascun oggetto    
    this.totale_carrello = this.carrello.reduce((acc, cur) => {
      const sommaParziale = cur.prodotti.reduce((subAcc, subCur) => subAcc + subCur.prezzo, 0);
      return acc + sommaParziale;
    }, 0);


  }

  onRemove(prodotto: Prodotto) {

    prodotto.selezionato = false;

    // recupero il carrello del cliente
    let carrello = this.carrello.filter((carrello: Carrello) => carrello.id_cliente == this.cliente.id)[0];

    // recupero l'indice del prodotto che sto togliendo
    const index = carrello.prodotti.indexOf(prodotto, 0);
    if (index >= -1) {
      carrello.prodotti.splice(index, 1);
    }

    // se il prodotto era obbligatorio tolgo tutto
    if (prodotto.obbligatorio) {
      this.disable_all = true;

      carrello.prodotti = [];

      // deseleziono tutti i prodotti
      for (let prodotto of this.prodotti) {
        prodotto.selezionato = false
      }
    }

    // se il prodotto era obbligatorio tolgo tutto
    if (prodotto.id == 1) {
      this.disable_skipass = true;

      carrello.prodotti = carrello.prodotti.filter((prod: Prodotto) => prod.id_categoria !== 13 && prodotto.id !== prod.id);

      // deseleziono tutti i prodotti
      for (let prodotto of this.prodotti) {
        if (prodotto.id_categoria == 13)
          prodotto.selezionato = false
      }
    }

    this.totale = 0;

    // ciclo per i prodotti selzezionati
    for (let prodotto_carrello of carrello.prodotti) {
      this.totale += prodotto_carrello.prezzo;
    }

    carrello.totale = this.totale;

    // Utilizza il metodo reduce per sommare il campo "valore" di ciascun oggetto    
    this.totale_carrello = this.carrello.reduce((acc, cur) => {
      const sommaParziale = cur.prodotti.reduce((subAcc, subCur) => subAcc + subCur.prezzo, 0);
      return acc + sommaParziale;
    }, 0);
  }

  onCheckout() {

    // Se ci sono più clienti e non sono all'ultimo, passo al prossimo
    if (this.data.clienti.length > 1 && this.i !== this.data.clienti.length - 1) {

      this.i++;
      this.getData();
      this.listaProdotti.nativeElement.scrollTop = 0;

    } else {

      // Recupero le info da richiedere
      this.getInfo();

      // Se non ci sono info da richiedere chiudo tutto
      if (!this.informazione) {

        this.checkout.carrello = this.carrello
        this.checkout.totale = this.totale_carrello;
        this.dialogRef.close(this.checkout);

      }
    }
  }

  onSubmit() {
    let ritiro = this.formInfo.get('ritiro')?.value || '';

    //ciclo nel carrello
    for (let carrello of this.carrello) {

      //ciclo nei prodotti
      for (let giornaliero of this.informazioni.giornalieri) {

        let prodotto = carrello.prodotti.filter(prod => prod.id == giornaliero.id)

        if (prodotto[0]) {

          // recupero il giorno selezionato e lo setto come inizio e fine validità
          let giorno = this.formInfo.get('giornaliero_' + prodotto[0].id)?.value;
          prodotto[0].data_utilizzo = this.datePipe.transform(giorno, 'yyyy-MM-dd');

          let date_21_1 = new Date();
          date_21_1.setHours(21, 1, 0);
          let date_23 = new Date();
          date_23.setHours(23, 0, 0);
          let date_23_1 = new Date();
          date_23_1.setHours(23, 1, 0);
          let date_08 = new Date();
          date_08.setHours(8, 0, 0);

          let oggi = new Date()

          if (differenceInCalendarDays(new Date(giorno), oggi) == 1 && prodotto[0].id_categoria == 13) {
            date_08.setDate(date_08.getDate() + 1);

            if (oggi >= date_21_1 && oggi <= date_23) {
              prodotto[0].supplemento = 0.50;
            } else if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto[0].supplemento = 2.00
            }
          } else if (differenceInCalendarDays(new Date(giorno), oggi) == 0 && prodotto[0].id_categoria == 13) {
            date_23_1.setDate(date_23_1.getDate() - 1);
            if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto[0].supplemento = 2.00;
            }
          } else {
            prodotto[0].supplemento = 0.00
          }

        }
      }

      // ciclo nei giornalieri specifici
      for (let giornaliero of this.informazioni.giornalieri_specifici) {

        let prodotto = carrello.prodotti.filter(prod => prod.id == giornaliero.id)

        if (prodotto[0]) {

          // recupero il giorno selezionato e lo setto come inizio e fine validità
          let giorno = this.formInfo.get('giornaliero_specifico_' + prodotto[0].id)?.value;
          prodotto[0].data_utilizzo = this.datePipe.transform(giorno, 'yyyy-MM-dd');

          let date_21_1 = new Date();
          date_21_1.setHours(21, 1, 0);
          let date_23 = new Date();
          date_23.setHours(23, 0, 0);
          let date_23_1 = new Date();
          date_23_1.setHours(23, 1, 0);
          let date_08 = new Date();
          date_08.setHours(8, 0, 0);

          let oggi = new Date()

          if (differenceInCalendarDays(new Date(giorno), oggi) == 1 && prodotto[0].id_categoria == 13) {
            date_08.setDate(date_08.getDate() + 1);

            if (oggi >= date_21_1 && oggi <= date_23) {
              prodotto[0].supplemento = 0.50;
            } else if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto[0].supplemento = 2.00
            }
          } else if (differenceInCalendarDays(new Date(giorno), oggi) == 0 && prodotto[0].id_categoria == 13) {
            date_23_1.setDate(date_23_1.getDate() - 1);
            if (oggi >= date_23_1 && oggi <= date_08) {
              prodotto[0].supplemento = 2.00;
            }
          } else {
            prodotto[0].supplemento = 0.00
          }

        }
      }

      this.totale = 0;
      // ciclo per i prodotti selzezionati
      for (let prodotto_carrello of carrello.prodotti) {
        this.totale += (prodotto_carrello.prezzo + (prodotto_carrello.supplemento || 0));
      }

      carrello.totale = this.totale;

    }


    // Utilizza il metodo reduce per sommare il campo "valore" di ciascun oggetto    
    this.totale_carrello = this.carrello.reduce((acc, cur) => {
      const sommaParziale = cur.prodotti.reduce((subAcc, subCur) => subAcc + (subCur.prezzo + (subCur.supplemento || 0)), 0);
      return acc + sommaParziale;
    }, 0);

    this.checkout.note = ritiro.length > 0 ? 'Punto di ritiro: ' + ritiro : ''
    this.checkout.carrello = this.carrello;
    this.checkout.totale = this.totale_carrello;
    this.dialogRef.close(this.checkout);
  }

  // Funzione per disabilitare specifici giorni con un parametro personalizzato
  onDisableDateGiornalieri(giorni: any, inizio_validita: any, fine_validita: any) {
    return (current: Date): boolean => {

      let date_inizio = new Date(inizio_validita)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(fine_validita)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine validità
      if (date_inizio <= current && date_fine >= current) {

        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        if (differenceInCalendarDays(current, oggi) <= 0) {
          if (date_08.getTime() >= oggi.getTime()) {
            // Recupero i giorni del prodotto e mostro solo quelli
            const day = current.getDay();
            const numeriConvertiti = this.convertiArrayNomiInNumeri(giorni);
            return !numeriConvertiti.includes(day);
          } else {
            return true;
          }
        } else {
          // Recupero i giorni del prodotto e mostro solo quelli
          const day = current.getDay();
          const numeriConvertiti = this.convertiArrayNomiInNumeri(giorni);
          return !numeriConvertiti.includes(day);
        }
      } else
        return true;
    };
  }

  // Funzione per disabilitare specifici giorni con un parametro personalizzato
  onDisableDateGiornalieriSpecifici(date_specifiche_dal: any, date_specifiche_al: any) {
    return (current: Date): boolean => {

      let date_inizio = new Date(date_specifiche_dal)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(date_specifiche_al)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine validità
      if (date_inizio <= current && date_fine >= current) {
        // Mostro solo le date maggiori rispetto alla data attuale

        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        if (differenceInCalendarDays(current, oggi) <= 0) {
          if (date_08.getTime() >= oggi.getTime()) {
            return false
          } else {
            return true;
          }
        } else
          return false;
      } else
        return true;
    };
  }

  convertiArrayNomiInNumeri(nomiGiorni: string[]): number[] {
    const numeriGiorni: number[] = [];

    for (const nomeGiorno of nomiGiorni) {
      let indice = this.giorni.indexOf(nomeGiorno) + 1;
      if (indice !== -1) {
        if (indice == 7)
          indice = 0;
        numeriGiorni.push(indice);
      }
    }

    return numeriGiorni;
  }

  onIndietro() {
    this.i--;
    this.getData();
    this.listaProdotti.nativeElement.scrollTop = 0;
  }

  onIndietroInformazione() {
    this.informazione = false;
    this.getData();
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
    var dialogRef = this.dialog.open(ModificaProdottoTesseramentoComponent, { autoFocus: false, data: { prodotto: item }, width: '750px' });
    dialogRef?.afterClosed().subscribe((data) => {

      if (data) {
        item.prezzo = parseFloat(data.prezzo);
      }

    })
  }

  onClose() {
    this.dialogRef.close(false);
  }

}
