import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-aggiungi-punto-ritiro',
  templateUrl: './aggiungi-punto-ritiro.component.html',
  styleUrls: ['./aggiungi-punto-ritiro.component.css']
})
export class AggiungiPuntoRitiroComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiPuntoRitiroComponent>, private apiService: ApiService) { }

  formPuntoRitiro!: FormGroup;

  error_messages = {
    'valore': [
      { type: 'required', message: "Inserire un valore per procedere con la creazione di un punto di ritiro" },
    ],
    'descrizione': [
      { type: 'required', message: "Inserire una descrizione per procedere con la creazione di un punto di ritiro" },
    ]
  }

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formPuntoRitiro = new FormGroup({
      valore: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let punto_ritiro = {
      valore: this.formPuntoRitiro.get('valore')?.value,
      descrizione: this.formPuntoRitiro.get('descrizione')?.value
    }

    // Aggiungo il punto di ritiro
    this.apiService.addPuntoRitiro(punto_ritiro).subscribe({
      next: (data) => this.apiService.setPuntiRitiro(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(false);
  }

}
