import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-gruppo',
  templateUrl: './elimina-gruppo.component.html',
  styleUrls: ['./elimina-gruppo.component.css']
})
export class EliminaGruppoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EliminaGruppoComponent>, @Inject(MAT_DIALOG_DATA) public data: {id: number}, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(true)
  }

  onElimina(){  
      
    this.apiService.deleteGruppo(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/gruppi'])
    })  

  }
}
