import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Categoria } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-aggiungi-categoria',
  templateUrl: './aggiungi-categoria.component.html',
  styleUrls: ['./aggiungi-categoria.component.css']
})
export class AggiungiCategoriaComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiCategoriaComponent>, private apiService: ApiService) { }

  formCategoria!: FormGroup;

  categorie: Categoria[] = [];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione della categoria'}
    ],    
  }

  ngOnInit(): void {

    this.formCategoria = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null),
      posizione: new FormControl(null)
    })

    this.apiService.getCategorie().subscribe({
      next: (data) => this.categorie = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onClose() {
    this.dialogRef.close({result: false});
  }

  onSubmit() {

    let categoria = {
      nome: this.formCategoria.get('nome')?.value,
      descrizione:this.formCategoria.get('descrizione')?.value,
      posizione:this.formCategoria.get('posizione')?.value || "ultimo"
    }    

    this.apiService.addCategoria(categoria).subscribe({
      next: (data) => {
        this.apiService.setCategorie(); 
        this.dialogRef.close({result: true, data: data.id})
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {}
    })

  }
}
