import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Tesseramento } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-tesseramento',
  templateUrl: './modifica-tesseramento.component.html',
  styleUrls: ['./modifica-tesseramento.component.css']
})
export class ModificaTesseramentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaTesseramentoComponent>, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: { id: number }) { }

  formTessera!: FormGroup;

  tesseramento?: Tesseramento;

  ngOnInit(): void {

    // Formo i controllli per il form
    this.formTessera = new FormGroup({
      codice: new FormControl(null),
      attivo: new FormControl(null)
    })

    // Recupero il tesseramento in base all'id
    this.apiService.getTesseramento(this.data.id).subscribe({
      next: (data) => this.tesseramento = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Associo i dati del tesseramento al form
        this.formTessera.setValue({
          codice: this.tesseramento?.codice_tesseramento,
          attivo: this.tesseramento?.attivo ? true : false
        })

      }
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let dati = {
      codice: this.formTessera.get('codice')?.value,
      attivo: this.formTessera.get('attivo')?.value || false
    }
    
    // Effettuo l'update del tesseramento con i nuovi dati
    this.apiService.updateTesseramento(this.data.id, dati).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onClose() {
    this.dialogRef.close(true);
  }

}
