import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Ordine } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-aggiungi-movimento',
  templateUrl: './aggiungi-movimento.component.html',
  styleUrls: ['./aggiungi-movimento.component.css']
})
export class AggiungiMovimentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiMovimentoComponent>, private apiService: ApiService, private dataPipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: { tipo?: string }) { }

  formMovimento!: FormGroup;

  ordini: Ordine[] = [];
  clienti: Cliente[] = [];

  filteredClienti: Cliente[] = [];

  error_messages = {
    'descrizione': [
      { type: 'required', message: 'Inserire una descrizione per procedere con la creazione di un movimento' }
    ],
    'tipo': [
      { type: 'required', message: 'Inserire un conto per procedere con la creazione di un movimento' }
    ],
    'pagato': [
      { type: 'required', message: 'Inserire il prezzo pagato per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'residuo': [
      { type: 'required', message: 'Inserire un prezzo residuo per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'totale': [
      { type: 'required', message: 'Inserire un prezzo totale per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'data': [
      { type: 'required', message: 'Inserire una data per procedere con la creazione di un movimento' },
    ]
  }

  ngOnInit(): void {
    this.formMovimento = new FormGroup({
      tipo: new FormControl(null, Validators.required),
      metodo: new FormControl(null, Validators.required),
      descrizione: new FormControl(null, Validators.required),
      cliente: new FormControl(null),
      ordine: new FormControl(null),
      pagato: new FormControl(null, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.required]),
      residuo: new FormControl(null, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
      totale: new FormControl(null, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.required]),
      data: new FormControl(null)
    })

    if (this.data.tipo == 'cassa') {
      this.formMovimento.get('metodo')?.disable()
      this.formMovimento.patchValue({ metodo: 'Contanti' });
    }

    if (this.data.tipo == 'ordine')
      this.formMovimento.patchValue({ tipo: 'Incasso' });

    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.filteredClienti = this.clienti.slice(0, 10)
        this.formMovimento.get('ordine')?.disable();
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }

  filtra(name: string): Cliente[] {
    const filterValue = name?.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.filtra(ricerca).slice(0, 10);
  }

  onTipo(tipo: string) {
    if (tipo == 'Incasso') {

      let cliente = this.formMovimento.get('cliente')?.value;

      if (cliente != null) {
        this.formMovimento.get('ordine')?.enable();
      }
    } else {

      this.formMovimento.patchValue({
        ordine: null
      })

      this.formMovimento.get('ordine')?.disable();


    }
  }

  onCliente(cliente: Cliente) {

    // Se selezionato un cliente procedo
    if (cliente) {

      let tipo = this.formMovimento.get('tipo')?.value;

      if (tipo == 'Incasso') {

        // Abilito l'input degli ordini e pulisco il valore al suo interno
        this.formMovimento.get('ordine')?.enable();
      }

      this.formMovimento.patchValue({ ordine: null })

      // Recupero gli ordini del cliente
      this.apiService.getOrdiniCliente(cliente.id).subscribe({
        // Recupero gli ordini del cliente non pagati
        next: (data) => this.ordini = data.filter((ordine: Ordine) => ordine.stato?.toLowerCase() == 'non pagato'),
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => { }
      })


    } else {

      // Se è stato rimosso il cliente blocco l'input e lo pulisco
      this.ordini = [];
      this.formMovimento.get('ordine')?.disable();
      this.formMovimento.patchValue({ ordine: null })
    }
  }

  onTotale(value: string) {

    let pagato = this.formMovimento.get('pagato')?.value;

    this.formMovimento.patchValue({ residuo: (pagato - +value).toFixed(2) })

  }

  onPagato(value: string) {

    let totale = this.formMovimento.get('totale')?.value;

    this.formMovimento.patchValue({ residuo: (totale - +value).toFixed(2) })

  }

  onOrdine(ordine: Ordine) {

    // Se c'è un ordine proseguo
    if (ordine) {

      // Recupero totale e pagato
      let totale = +this.formMovimento.get('totale')?.value || 0.00;
      let pagato = +this.formMovimento.get('pagato')?.value || 0.00;

      // Calcolo il residuo aggiungendo il prezzo dell'ordine
      let residuo = (totale + ordine.prezzo) - (pagato + ordine.prezzo);

      // Salvo i dati ottenuti nel form
      this.formMovimento.patchValue({ totale: (totale + ordine.prezzo).toFixed(2), pagato: (pagato + ordine.prezzo).toFixed(2), residuo: residuo.toFixed(2) })

    } else {

      // Salvo i dati ottenuti nel form
      this.formMovimento.patchValue({ totale: '0.00', pagato: '0.00', residuo: '0.00' })
    }
  }

  onSubmit() {

    let movimento = {
      tipo: this.formMovimento.get('tipo')?.value,
      metodo: this.formMovimento.get('metodo')?.value,
      descrizione: this.formMovimento.get('descrizione')?.value,
      id_cliente: this.formMovimento.get('cliente')?.value ? this.formMovimento.get('cliente')?.value.id : null,
      id_ordine: this.formMovimento.get('ordine')?.value ? this.formMovimento.get('ordine')?.value.id : null,
      pagato: this.formMovimento.get('pagato')?.value || 0.00,
      residuo: this.formMovimento.get('residuo')?.value || 0.00,
      totale: this.formMovimento.get('totale')?.value || 0.00,
      data: this.dataPipe.transform(this.formMovimento.get('data')?.value, 'yyyy-MM-dd')
    }

    this.apiService.addMovimento(movimento).subscribe({
      next: (data) => this.apiService.setMovimenti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

  onSubmitAndReset() {

    let movimento = {
      tipo: this.formMovimento.get('tipo')?.value,
      metodo: this.formMovimento.get('metodo')?.value,
      descrizione: this.formMovimento.get('descrizione')?.value,
      id_cliente: this.formMovimento.get('cliente')?.value ? this.formMovimento.get('cliente')?.value.id : null,
      id_ordine: this.formMovimento.get('ordine')?.value ? this.formMovimento.get('ordine')?.value.id : null,
      pagato: this.formMovimento.get('pagato')?.value || 0.00,
      residuo: this.formMovimento.get('residuo')?.value || 0.00,
      totale: this.formMovimento.get('totale')?.value || 0.00,
      data: this.dataPipe.transform(this.formMovimento.get('data')?.value, 'yyyy-MM-dd')
    }

    this.apiService.addMovimento(movimento).subscribe({
      next: (data) => this.apiService.setMovimenti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.formMovimento.reset();
        this.formMovimento.markAsPristine()
        this.formMovimento.markAsUntouched();
      }
    })

  }

}
