import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { ApiService } from '../service/api.service';
import { Categoria, Prodotto, Stagione, Statistiche } from '../shared/interfacce';
import * as moment from 'moment';
import 'chartjs-adapter-moment';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.css']
})
export class StatisticheComponent implements OnInit {

  public generalChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
  };

  public lineOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      }
    }
  };

  public nuoviTesseramentiData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      { data: [], label: 'Nuovi tesseramenti', tension: 0.2, borderColor: 'grey', borderWidth: 1 },
    ]
  };

  public ordiniData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      { data: [], label: 'Ordini', tension: 0.2, borderColor: 'grey', borderWidth: 1 },
    ]
  };

  public etaClientiData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      { data: [], label: 'Numero di clienti', tension: 0.5, borderColor: 'grey', borderWidth: 1 },
    ]
  };

  public percClientiData: ChartConfiguration<'pie'>['data'] = {
    labels: [],
    datasets: [
      { data: [], backgroundColor: ['red', 'green', 'yellow'], hoverBackgroundColor: ['red', 'green', 'yellow'] },
    ],
  };

  public prodottiCatData: ChartConfiguration<'pie'>['data'] = {
    labels: [],
    datasets: [
      { data: [], label: 'Prodotti più venduti' },
    ]
  };

  constructor(private apiService: ApiService, private datePipe: DatePipe) { }

  statistiche?: Statistiche;
  stagione?: Stagione;

  categorie: Categoria[] = [];
  prodotti: Prodotto[] = []

  carica: boolean = true;
  show_clienti_chart: boolean = true;
  show_prodotti_chart: boolean = false;
  show_categorie_chart: boolean = true;
  show_registrazioni_chart: boolean = true;

  formStatistiche!: FormGroup;

  ngOnInit(): void {

    // Assegno al pacchetto moment il local 'it' per le date nei chart
    moment.locale('it');

    // Recupero le date della stagione
    this.apiService.getStagione().subscribe({
      next: (data) => this.stagione = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        this.formStatistiche = new FormGroup({
          data: new FormControl([this.datePipe.transform(this.stagione?.inizio_stagione, 'yyyy-MM-dd'), this.datePipe.transform(this.stagione?.fine_stagione, 'yyyy-MM-dd')], Validators.required),
          categoria: new FormControl(null),
          prodotto: new FormControl(null)
        })

        // Recupero le categorie
        this.apiService.getCategorie().subscribe({
          next: (data) => this.categorie = [...data],
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Recupero i prodotti
            this.apiService.getProdotti().subscribe({
              next: (data) => this.prodotti = [...data],
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {
                this.getData();
              }
            })

          }
        })
      }
    })

  }

  getData() {

    this.carica = true;

    // Resetto i chart
    this.nuoviTesseramentiData.labels = []
    this.nuoviTesseramentiData.datasets[0].data = []

    this.ordiniData.labels = []
    this.ordiniData.datasets[0].data = []

    this.etaClientiData.labels = []
    this.etaClientiData.datasets[0].data = []

    this.percClientiData.labels = []
    this.percClientiData.datasets[0].data = []

    this.prodottiCatData.labels = []
    this.prodottiCatData.datasets[0].data = []

    const data_inizio = this.datePipe.transform(this.formStatistiche.get('data')?.value[0], 'yyyy-MM-dd')
    const data_fine = this.datePipe.transform(this.formStatistiche.get('data')?.value[1], 'yyyy-MM-dd')
    const giorni = moment(data_fine).diff(moment(data_inizio), 'days');

    const categoria = this.formStatistiche.get('categoria')?.value || 0
    const prodotto = this.formStatistiche.get('prodotto')?.value || 0

    this.show_clienti_chart = prodotto === 3 || categoria === 1 || (prodotto === 0 && categoria === 0) ? true : false;
    this.show_prodotti_chart = categoria !== 0 ? true : false
    this.show_categorie_chart = prodotto === 0 ? true : false;
    this.show_registrazioni_chart = prodotto === 0 && categoria === 0 ? true : false;    

    this.lineOptions.scales = giorni > 31 ? { x: { type: 'time', time: { unit: 'month' } } } : { x: { type: 'time', time: { unit: 'day' } } }

    // Recupero le statistiche della stagione
    this.apiService.getStats(data_inizio, data_fine, categoria, prodotto).subscribe({
      next: (statistiche) => {

        // Creo il chart per fasce dei clienti
        this.percClientiData.labels = ['Bambino', 'Under 14', 'Adulto'];
        this.percClientiData.datasets[0].data = [statistiche.percClienti['Bambino'], statistiche.percClienti['Under 14'], statistiche.percClienti['Adulto']];

        // Creo il chart per il numero di tesseramenti nei mesi
        for (let tess of statistiche.tesseramentiPerData) {
          this.nuoviTesseramentiData.datasets[0].data.push(tess.tesseramenti)
          this.nuoviTesseramentiData.labels?.push(new Date(tess.data))
        }

        // Creo il chart per il numero di ordini nei mesi
        for (let ord of statistiche.ordiniPerData) {
          this.ordiniData.datasets[0].data.push(ord.ordini)
          this.ordiniData.labels?.push(new Date(ord.data))
        }

        // Ordino l'array degli ordini per categoria in ordine crescente
        statistiche.prodottiCat.sort(function (a, b) { return a.ordini - b.ordini; });

        // Creo il chart per il numero di ordini per categoria
        for (let prod of statistiche.prodottiCat) {
          if (prod.categoria) {
            this.prodottiCatData.datasets[0].data.push(prod.ordini)
            this.prodottiCatData.labels?.push(prod.categoria)
          }
        }

        // Creo il chart per la suddivisione di età dei clienti
        for (let cli of statistiche.etaClienti) {
          this.etaClientiData.datasets[0].data.push(cli.clienti)
          this.etaClientiData.labels?.push(new Date().getFullYear() - cli.anno + ' anni')
        }

        this.statistiche = statistiche;
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.carica = false
    })
  }

  onStatistiche() {
    this.getData();
  }

}
