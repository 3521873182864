import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-ordine',
  templateUrl: './elimina-ordine.component.html',
  styleUrls: ['./elimina-ordine.component.css']
})
export class EliminaOrdineComponent {

  constructor(private dialogRef: MatDialogRef<EliminaOrdineComponent>, @Inject(MAT_DIALOG_DATA) public data: { id_ordine: number }, private apiService: ApiService, private router: Router) { }

  onClose() {
    this.dialogRef.close(false)
  }

  onElimina() {

    // Elimino l'ordine
    this.apiService.deleteOrdine(this.data.id_ordine).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/storico'])
    })

  }

}
