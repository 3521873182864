import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Prodotto } from 'src/app/shared/interfacce';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-esportazione-tesseramento',
  templateUrl: './esportazione-tesseramento.component.html',
  styleUrls: ['./esportazione-tesseramento.component.css']
})
export class EsportazioneTesseramentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EsportazioneTesseramentoComponent>, private apiService: ApiService, private datePipe: DatePipe) { }

  formEsportazione!: FormGroup;

  prodotti: Prodotto[] = [];
  clienti: Cliente[] = [];

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formEsportazione = new FormGroup({
      data: new FormControl(null, Validators.required),
      prodotto: new FormControl(null),
      cliente: new FormControl(null),
      stato: new FormControl(null)
    })

    // Recupero i prodotti
    this.apiService.getProdotti().subscribe({
      next: (data) => this.prodotti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Recupero i clienti
        this.apiService.getClienti().subscribe({
          next: (data) => this.clienti = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => { }
        })
      }
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let dati = {
      data_inizio: this.datePipe.transform((this.formEsportazione.get('data')?.value ? this.formEsportazione.get('data')?.value[0] : this.formEsportazione.get('data')?.value), 'yyyy-MM-dd'),
      data_fine: this.datePipe.transform(this.formEsportazione.get('data')?.value ? this.formEsportazione.get('data')?.value[1] : this.formEsportazione.get('data')?.value, 'yyyy-MM-dd'),
      prodotto: this.formEsportazione.get('prodotto')?.value || '',
      cliente: this.formEsportazione.get('cliente')?.value || '',
      stato: this.formEsportazione.get('stato')?.value || '',
    }

    // Recupero i tesseramenti filtrati
    this.apiService.getTesseramentiFiltrati(dati.data_inizio, dati.data_fine, dati.prodotto, dati.cliente, dati.stato).subscribe({
      next: (data) => {

        // Formo excel con i tesseramenti
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(data);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 30 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Dettaglio Ordini');
        XLSX.writeFile(workBook, 'dettaglio_ordini_filtrati.xlsx');
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

  onClose() {
    this.dialogRef.close();
  }
}
