import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './service/api.service';
import { StorageService } from './service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user = this.storageService.getUser();
    if (!user.id) {
      this.router.navigate(["/login"]);
      return false;
    }
    return new Observable<boolean>(o => {
      this.apiService.isAuthorized().subscribe(
        res => {
          if (!res) {
            this.router.navigate(["/login"]);
            o.next(false);
          }
          else {
            o.next(true);
          }
        });
    });
  }

}
