import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Carrello, DettaglioOrdine, DettaglioOrdineCliente } from 'src/app/shared/interfacce';
import { ModificaProdottiStoricoOrdineComponent } from '../modifica-prodotti-storico-ordine/modifica-prodotti-storico-ordine.component';

@Component({
  selector: 'app-modifica-storico-ordine',
  templateUrl: './modifica-storico-ordine.component.html',
  styleUrls: ['./modifica-storico-ordine.component.css']
})
export class ModificaStoricoOrdineComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaStoricoOrdineComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService, private datePipe: DatePipe, private dialog: MatDialog) { }

  formOrdine!: FormGroup;

  ordine?: DettaglioOrdine;
  dettaglio: DettaglioOrdineCliente[] = [];

  error_messages = {
    'data': [
      { type: 'required', message: "Inserire una data per procedere con la modifica dell'ordine" },
      { type: 'matDatepickerFilter', message: 'Inserire una data valida' }
    ],
    'metodo': [
      { type: 'required', message: 'Inserire un metodo per procedere con la creazione del tesseramento' }
    ],
    'eseguitoDa': [
      { type: 'required', message: 'Inserire una persona per procedere con la creazione del tesseramento' }
    ]
  }

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formOrdine = new FormGroup({
      data: new FormControl(null, Validators.required),
      metodo: new FormControl(null, Validators.required),
      eseguitoDa: new FormControl(null, Validators.required),
      stato: new FormControl(null),
      note: new FormControl(null),
      carrello: new FormControl(null)
    })

    // Recupero il dettaglio dell'ordine
    this.apiService.getDettaglioOrdine(this.data.id).subscribe({
      next: (data) => this.ordine = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Associo l'ordine al form
        this.formOrdine.setValue({
          data: this.datePipe.transform(this.ordine?.data_creazione, 'yyyy-MM-dd'),
          metodo: this.ordine?.metodo,
          eseguitoDa: this.ordine?.eseguitoDa,
          stato: this.ordine?.stato == 'pagato' ? true : false,
          note: this.ordine?.note,
          carrello: ''
        })

        // Ciclo tra i clienti nell'ordine
        for (let cliente of this.ordine!.clienti) {

          let dettaglio: { cliente: any, prodotti: any } = { cliente: {}, prodotti: [] };

          // Recupero i dati del cliente
          this.apiService.getCliente(cliente.id_cliente).subscribe({
            next: (data) => dettaglio.cliente = data,
            error: (err) => this.apiService.Error(err.error.message),
            complete: () => {
              // Ciclo tra i prodotti del cliente nell'ordine
              for (let prodotto of cliente.prodotti) {

                // Recupero i dati del prodotto
                this.apiService.getProdotto(prodotto.id_prodotto).subscribe({
                  next: (data) => dettaglio.prodotti.push(data),
                  error: (err) => this.apiService.Error(err.error.message),
                  complete: () => { }
                })
              }

              this.dettaglio.push(dettaglio);
            }
          })
        }

      }
    })
  }

  onProdotti() {
    var dialogRef = this.dialog.open(ModificaProdottiStoricoOrdineComponent, { data: { dettaglio: this.dettaglio }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(data => {
      if (data) {
        this.formOrdine.patchValue({
          carrello: data.carrello,
          note: data.note,
        })
      }
    })
  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let ordine = {
      data: this.datePipe.transform(this.formOrdine.get('data')?.value, 'yyyy-MM-dd'),
      metodo: this.formOrdine.get('metodo')?.value,
      eseguitoDa: this.formOrdine.get('eseguitoDa')?.value,
      stato: this.formOrdine.get('stato')?.value || false,
      note: this.formOrdine.get('note')?.value,
      carrello: this.formOrdine.get('carrello')?.value
    }

    // Effettuo l'update dell'ordine con i nuovi dati
    this.apiService.updateOrdine(this.data.id, ordine).subscribe({
      next: (data) => { },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })
  }

  onClose() {
    this.dialogRef.close(false);
  }

}
