<!-- #rla serve a segnalare al componente se il link è attivo o no.
  Ogni link deve avere una variabile diversa quindi #rla1 è per il primo link
  (#rla5 è per il primo link del menu mobile) e così via -->
<div id="wrapper">
  <ul nz-menu *ngIf="!collapse" nzMode="inline" class="border border-top-0" id="menu">
    <div id="logo" class="my-4 mx-auto"></div>
    <li routerLink="/home" nzMatchRouter nz-menu-item nz-tooltip nzTooltipPlacement="right"
      [nzTooltipTitle]="collapse ? 'Home' : ''">
      <span nz-icon nzType="home" nzTheme="outline"></span>
      <span>Home</span>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla1="routerLinkActive" [nzOpen]="rla1.isActive"
      nzTitle="Clienti e Gruppi" nzIcon="user">
      <ul>
        <li nz-menu-item routerLink="/clienti" nzMatchRouter>Clienti</li>
        <li nz-menu-item routerLink="/gruppi" nzMatchRouter>Gruppi</li>
      </ul>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla2="routerLinkActive" [nzOpen]="rla2.isActive"
      nzTitle="Ordini e prodotti" nzIcon="shopping-cart">
      <ul>
        <li nz-menu-item routerLink="/categorie" nzMatchRouter>Tabella Categorie</li>
        <li nz-menu-item routerLink="/prodotti" nzMatchRouter>Tabella Prodotti</li>
        <li nz-menu-item routerLink="/promozioni" nzMatchRouter>Tabella Promozioni</li>        
        <li nz-menu-item routerLink="/codici-sconto" nzMatchRouter>Tabella Codici Sconto</li>
        <li nz-menu-item routerLink="/storico" nzMatchRouter>Storico Ordini Arrivati</li>
        <li nz-menu-item routerLink="/tesseramenti" nzMatchRouter>Storico Ordini in Dettaglio</li>
      </ul>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla3="routerLinkActive" [nzOpen]="rla3.isActive" nzTitle="Gestione"
      nzIcon="area-chart">
      <ul>
        <li nz-menu-item routerLink="/tesseramenti-sciclub" nzMatchRouter>Tesseramenti Sci Club</li>
        <li nz-menu-item routerLink="/card-vialattea" nzMatchRouter>Card ViaLattea</li>
        <li nz-menu-item routerLink="/card-skiopen" nzMatchRouter>Card SkiPassOpen</li>
        <li nz-menu-item routerLink="/giornalieri-vialattea" nzMatchRouter>Giornalieri ViaLattea</li>
        <li nz-menu-item routerLink="/assicurazioni" nzMatchRouter>Assicurazioni</li>        
        <li nz-menu-item routerLink="/clienti-newsletter" nzMatchRouter>Clienti Newsletter</li>        
        <li nz-menu-item routerLink="/movimenti" nzMatchRouter>Prima nota</li>
        <li nz-menu-item routerLink="/punti-ritiro" nzMatchRouter>Punti di ritiro</li>
        <li nz-menu-item routerLink="/statistiche" nzMatchRouter>Statistiche</li>
      </ul>
    </li>    
    <li nz-submenu routerLinkActive="nzOpen" #rla4="routerLinkActive" [nzOpen]="rla4.isActive" nzTitle="Impostazioni" nzIcon="setting">
      <ul>
        <li nz-menu-item routerLink="/utenti" *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Gestione operatori</li>
        <li nz-menu-item routerLink="/configurazioni" *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Gestione configurazioni</li>
        <li nz-menu-item routerLink="/log"  *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Storico modifiche</li>
        <li nz-menu-item (click)="onImpostazioni()">Modifica password</li>
      </ul>
    </li>
    <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Cambia tema' : ''" (click)="onTheme()">
      <i class="bi" [ngClass]="theme ? ' bi-sun' : 'bi-moon'"></i>
      <span style="margin-left: 10px">Passa al tema {{ theme ? 'chiaro' : 'scuro' }} </span>
    </li>
    <a nz-menu-item target="_blank" [href]="shop_url" nzMatchRouter nz-menu-item
      nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Shop' : ''"
      class="text-decoration-none link-menu">
      <span nz-icon nzType="shop" nzTheme="outline"></span>
      <span>Vai allo shop</span>
      <span class="text-muted ms-1" nz-icon nzType="link" nzTheme="outline"></span>
    </a>
    <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Log-out' : ''" nz-popconfirm
      nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?." (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'">
      <span nz-icon nzType="logout" nzTheme="outline"></span>
      <span>Log-out</span>
    </li>
  </ul>
  <ng-container *ngIf="collapse">
    <div class="top-bar border-bottom px-3">
      <i class="bi bi-list fs-1 fw-bold cursor-pointer burger" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas-menu"></i>
      <img src="../../assets/img/scb_logo_lowres.png" style="height: 90%" alt="">
    </div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvas-menu">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="staticBackdropLabel">Sci Club Bussoleno</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body p-0">
        <ul nz-menu nzMode="inline" class="border-top-0 w-100 h-100">
          <div id="logo" class="my-4 mx-auto"></div>
          <li data-bs-dismiss="offcanvas" routerLink="/home" nzMatchRouter nz-menu-item nz-tooltip
            nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Home' : ''">
            <span nz-icon nzType="home" nzTheme="outline"></span>
            <span>Home</span>
          </li>
          <li nz-submenu routerLinkActive="nzOpen" #rla5="routerLinkActive" [nzOpen]="rla5.isActive"
            nzTitle="Clienti e Gruppi" nzIcon="user">
            <ul>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/clienti" nzMatchRouter>Clienti</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/gruppi" nzMatchRouter>Gruppi</li>
            </ul>
          </li>
          <li nz-submenu routerLinkActive="nzOpen" #rla6="routerLinkActive" [nzOpen]="rla6.isActive"
            nzTitle="Ordini e prodotti" nzIcon="shopping-cart">
            <ul>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/categorie" nzMatchRouter>Tabella Categorie</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/prodotti" nzMatchRouter>Tabella Prodotti</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/promozioni" nzMatchRouter>Tabella Promozioni</li>        
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/codici-sconto" nzMatchRouter>Tabella Codici Sconto</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/storico" nzMatchRouter>Storico Ordini Arrivati</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/tesseramenti" nzMatchRouter>Storico Ordini in Dettaglio</li>
            </ul>
          </li>
          <li nz-submenu routerLinkActive="nzOpen" #rla7="routerLinkActive" [nzOpen]="rla7.isActive" nzTitle="Gestione" nzIcon="area-chart">
            <ul>              
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/tesseramenti-sciclub" nzMatchRouter>Tesseramenti Sci Club</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/card-vialattea" nzMatchRouter>Card ViaLattea</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/card-skiopen" nzMatchRouter>Card SkiPassOpen</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/giornalieri-vialattea" nzMatchRouter>Giornalieri ViaLattea</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/assicurazioni" nzMatchRouter>Assicurazioni</li>        
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/clienti-newsletter" nzMatchRouter>Clienti Newsletter</li>        
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/movimenti" nzMatchRouter>Prima nota</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/punti-ritiro" nzMatchRouter>Punti di ritiro</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/statistiche" nzMatchRouter>Statistiche</li>
            </ul>
          </li>
          <li nz-submenu routerLinkActive="nzOpen" #rla8="routerLinkActive" [nzOpen]="rla8.isActive" nzTitle="Impostazioni" nzIcon="setting">
            <ul>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/utenti" *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Gestione operatori</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/configurazioni" *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Gestione configurazioni</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item routerLink="/log" *ngIf="user.tipo != 'Operatore'" nzMatchRouter>Storico modifiche</li>
              <li data-bs-dismiss="offcanvas" nz-menu-item (click)="onImpostazioni()">Modifica password</li>
            </ul>
          </li>
          <a nz-menu-item target="_blank" [href]="shop_url" nzMatchRouter
            nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Shop' : ''"
            class="text-decoration-none link-menu">
            <span nz-icon nzType="shop" nzTheme="outline"></span>
            <span>Vai allo shop</span>
            <span class="text-muted ms-1" nz-icon nzType="link" nzTheme="outline"></span>
          </a>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Cambia tema' : ''" (click)="onTheme()">
            <i class="bi" [ngClass]="theme ? ' bi-sun' : 'bi-moon'"></i>
            <span style="margin-left: 10px">Passa al tema {{ theme ? 'chiaro' : 'scuro' }} </span>
          </li>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="collapse ? 'Log-out' : ''"
            nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?." (nzOnConfirm)="onLogout()"
            [nzOkText]="'Esci'">
            <span data-bs-dismiss="offcanvas" nz-icon nzType="logout" nzTheme="outline"></span>
            <span>Log-out</span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="container my-auto" style="padding-left: 0px; padding-right: 0px; height: auto;">
    <router-outlet></router-outlet>
  </div>
</div>