import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FasceProdotti, Prodotto } from 'src/app/shared/interfacce';
import { saveAs } from 'file-saver';
import { EliminaImmagineProdottoComponent } from '../elimina-immagine-prodotto/elimina-immagine-prodotto.component';
import { EliminaProdottoComponent } from '../elimina-prodotto/elimina-prodotto.component';
import { ModificaProdottoComponent } from '../modifica-prodotto/modifica-prodotto.component';
import { environment } from 'src/environments/environment';
import { FascePrezziProdottoComponent } from '../fasce-prezzi-prodotto/fasce-prezzi-prodotto.component';
import { ModificaFascePrezziProdottoComponent } from '../modifica-fasce-prezzi-prodotto/modifica-fasce-prezzi-prodotto.component';
import { DuplicaProdottoComponent } from '../duplica-prodotto/duplica-prodotto.component';

@Component({
  selector: 'app-dettagli-prodotto',
  templateUrl: './dettagli-prodotto.component.html',
  styleUrls: ['./dettagli-prodotto.component.css']
})
export class DettagliProdottoComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private datePipe: DatePipe, private router: Router) { }

  id!: number;
  carica = true;

  prodotto?: Prodotto;
  fasce: FasceProdotti[] = [];

  immagine: string = '';

  url: string = environment.apiURL;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getData();
    });
  }

  getData() {

    // Recupero i dati del prodotto
    this.apiService.getProdotto(this.id).subscribe({
      next: (data) => this.prodotto = data,
      error: (err) => this.router.navigate(['/prodotti']),
      complete: () => {

        // In caso il prodotto sia un giornaliero (sia legato a dei giorni della settimana), parso i giorni salvati
        this.prodotto!.giorni = this.prodotto?.giornaliero ? JSON.parse(this.prodotto.giorni) : '';

        // Recupero l'immagina associata al prodotto
        this.apiService.getImmagine(this.prodotto!.immagine).subscribe({
          next: (data) => this.immagine = data.file,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            this.carica = false;

            // Recupero le fasce associate al prodotto
            this.apiService.setFasceProdotti(this.prodotto!.id)
            this.apiService.fasceProdottiChanged.subscribe({
              next: (data) => {
                this.fasce = data;
                
                // Ordino le fasce per età
                this.fasce.sort(function (a, b) {
                  return a.eta_max - b.eta_max;
                });
              },
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => { }
            })
          }
        })
      }
    })
  }

  onIndietro() {
    this.router.navigate(['/prodotti'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaProdottoComponent, { data: { id: this.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onFasce() {
    var dialogRef = this.dialog.open(FascePrezziProdottoComponent, { autoFocus: false, width: '1000px', data: { id_prodotto: this.prodotto?.id } })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onFascia(id: number) {
    var dialogRef = this.dialog.open(ModificaFascePrezziProdottoComponent, { autoFocus: false, width: '1000px', data: { id: id } })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onDuplica() {

    var dialogRef = this.dialog.open(DuplicaProdottoComponent, { autoFocus: false, width: '1000px', data: { prodotto: this.prodotto, fasce: this.fasce } })
    dialogRef?.afterClosed().subscribe((data) => {
      if (data.esito) {
        this.router.navigate(['/prodotti/' + data.id])
      }
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaProdottoComponent, { data: { id: this.id }, autoFocus: false, width: '750px' })
  }

  onEliminaImmagine() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(EliminaImmagineProdottoComponent, { data: { id: this.id, immagine: this.prodotto?.immagine }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onDownload() {

    // Se ho l'immagine procedo
    if (this.immagine.length > 0) {

      // Recupero il blob dell'immagine
      this.apiService.getImmagineProdotto(this.id).subscribe({
        next: (data) => {
          // Salvo l'immagine e la rendo scaricabile dal browser
          let downloadURL = window.URL.createObjectURL(data);
          saveAs(downloadURL, 'img_prodotto_' + this.id + '.jpg');
        },
        error: (err) => this.apiService.Error(err.error.message),
      })

    }
  }

  onPDF() {
    // Se il prodotto ha associato un pdf per l'assicurazione
    if (this.prodotto!.pdf_assicurazione.length > 0) {

      // Recupero il blob del pdf
      this.apiService.getPDFAssicurazione(this.id).subscribe({
        next: (data) => {
          // Salvo il pdf e la rendo apribile dal browser
          let downloadURL = window.URL.createObjectURL(data);
          let tab = window.open();
          tab!.location.href = downloadURL;
        },
        error: (err) => this.apiService.Error(err.error.message),
      })
    }
  }
}
