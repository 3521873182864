import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-prodotti-storico-ordine',
  templateUrl: './elimina-prodotti-storico-ordine.component.html',
  styleUrls: ['./elimina-prodotti-storico-ordine.component.css']
})
export class EliminaProdottiStoricoOrdineComponent {

  constructor(private dialogRef: MatDialogRef<EliminaProdottiStoricoOrdineComponent>, @Inject(MAT_DIALOG_DATA) public data: { id_ordine: number, id_cliente: number, cliente: string, id_prodotto: number, prodotto: string }, private apiService: ApiService) { }

  onClose() {
    this.dialogRef.close(false)
  }

  onElimina() {

    // Elimino il prodotto dall'ordine
    this.apiService.deleteProdottoOrdine(this.data.id_ordine, this.data.id_prodotto, this.data.id_cliente).subscribe({
      next: (data) => {},
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

}
