<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica ordine</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formOrdine" (ngSubmit)="onSubmit()" id="formOrdine">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>        
        <div class="row mt-3">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Data ordine</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>                    
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.data">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formOrdine.get('data')?.hasError(error.type) && (formOrdine.get('data')?.dirty || formOrdine.get('data')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col">
                <button nz-button nzType="primary" nzSize="large" type="button" class="w-100" (click)="onProdotti()">Modifica prodotti</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Metodo di pagamento</nz-form-label>
                    <nz-form-control>
                        <nz-select class="w-100" nzSize="large" nzShowSearch nzAllowClear formControlName="metodo">
                            <nz-option nzValue="Contanti" nzLabel="Contanti"></nz-option>
                            <nz-option nzValue="Satispay" nzLabel="SatisPay"></nz-option>
                            <nz-option nzValue="Bancomat" nzLabel="Bancomat"></nz-option>
                            <nz-option nzValue="Carta di credito" nzLabel="Carta di credito"></nz-option>
                            <nz-option nzValue="Bonifico" nzLabel="Bonifico"></nz-option>
                            <nz-option nzValue="MyBank" nzLabel="Bonifico istantaneo (MyBank)"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.metodo">                            
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formOrdine.get('metodo')?.hasError(error.type) && (formOrdine.get('metodo')?.dirty || formOrdine.get('metodo')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Eseguito da</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="eseguitoDa" nzSize="large" required [readOnly]="true">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.eseguitoDa">                            
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formOrdine.get('eseguitoDa')?.hasError(error.type) && (formOrdine.get('eseguitoDa')?.dirty || formOrdine.get('eseguitoDa')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col text-center">
                <span nzTooltipTitle="Seleziona il campo se l'ordine è stato pagato dal cliente, in caso non venisse selezionato lo si potrà selezionare successivamente nella sezione 'Prima Nota'!" nz-tooltip nzTooltipPlacement="top">
                    <label nz-checkbox formControlName="stato">Ordine pagato</label> 
                </span>                  
            </div>
        </div>      
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Note</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="note" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>                
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>

    <span nzTooltipTitle="Per modificare l'ordine, inserisci i dati con l'asterisco!" nz-tooltip nzTooltipPlacement="top" *ngIf="!formOrdine.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Ordine</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" form="formOrdine" *ngIf="formOrdine.valid">Modifica Ordine</button>
</mat-dialog-actions>