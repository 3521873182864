<mat-dialog-content *ngIf="!informazione">
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Seleziona prodotti per <span style="font-weight: bolder">{{cliente?.cognome}} {{cliente?.nome}}</span></h5>
    </div>
    <nz-divider></nz-divider>
    <div class="prodotti mt-3 mb-3">
        <nz-list>
            <nz-list-item *ngFor="let prodotto of prodotti" class="align-items-center" [ngClass]="{'bg-blue text-white': prodotto.selezionato, 'bg-grey': prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))}">
                <ng-container>
                    <nz-list-item-meta *ngIf="(!prodotto.selezionato && !(prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))))" (click)="onAdd(prodotto)" class="cursor-pointer">
                        <nz-list-item-meta-title>
                            <p class="d-inline px-3">{{prodotto?.nome}} - {{prodotto?.prezzo | currency: 'EUR'}} {{prodotto.stato}}</p>
                        </nz-list-item-meta-title>
                    </nz-list-item-meta>
                    <nz-list-item-meta *ngIf="(prodotto.selezionato && !(prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))))" (click)="onRemove(prodotto)" class="cursor-pointer">
                        <nz-list-item-meta-title>
                            <p class="d-inline px-3 text-white">{{prodotto?.nome}} - {{prodotto?.prezzo | currency: 'EUR'}} {{prodotto.stato}}</p>
                        </nz-list-item-meta-title>
                    </nz-list-item-meta>
                    <nz-list-item-meta *ngIf="prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))">
                        <nz-list-item-meta-title>
                            <p class="d-inline px-3">{{prodotto?.nome}} - {{prodotto?.prezzo | currency: 'EUR'}} {{prodotto.stato}}</p>
                        </nz-list-item-meta-title>
                    </nz-list-item-meta>
                    <ul nz-list-item-actions class="m-0">
                        <nz-list-item-action>
                            <button nz-button type="button" nzSize="large" class="me-2" *ngIf="!(prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio)))" nzTooltipTitle="Premere sulla icona della matita, per modificare in modo temporaneo il prezzo del prodotto!" nz-tooltip nzTooltipPlacement="top" (click)="onEdit($event, prodotto)"><i class="bi bi-pencil-square"></i></button>
                            <button nz-button type="button" nzSize="large" class="me-2 bg-grey" *ngIf="prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))" [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : 'Prodotto non disponibile')" nz-tooltip nzTooltipPlacement="top"><i class="bi bi-pencil-square"></i></button>
                            <button nz-button type="button" nzSize="large" class="bg-grey" *ngIf="prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio))" nz-tooltip [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : 'Prodotto non disponibile')"><i class="bi bi-cart-x-fill"></i></button>
                            <button nz-button type="button" nzSize="large" (click)="onAdd(prodotto)" *ngIf="!prodotto.selezionato && !(prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio)))"><i class="bi bi-cart-plus-fill"></i></button>
                            <button nz-button type="button" nzSize="large" (click)="onRemove(prodotto)" *ngIf="prodotto.selezionato && !(prodotto.disabilitato || (disable_all  && (!prodotto.obbligatorio)))"><i class="bi bi-cart-dash-fill"></i></button>
                        </nz-list-item-action>
                    </ul>
                </ng-container>
            </nz-list-item>
        </nz-list>
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-content *ngIf="informazione">
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Informazioni</h5>
    </div>
    <nz-divider></nz-divider>
    <div class="info mt-3 mb-3">
        <form [formGroup]="formInfo">
            <label id="ritiro" style="padding-bottom: 20px">Punti di ritiro:</label>            
            <nz-radio-group formControlName="ritiro">
                <label nz-radio nzValue="Bar dell'orologio">BAR DELL’OROLOGIO” a Sestriere (Piazza Agnelli, aperto tutti i giorni dalle 7:00 alle 18:00 – chiuso il giovedì pomeriggio)</label>
                <label nz-radio nzValue="Orgeas sport">Negozio “ORGEAS SPORT” a OULX, (Corso Torino 21, Oulx, aperto tutti i giorni 9:30-12:30 e 15-19:30)</label>
                <label nz-radio nzValue="Sede sociale">SEDE SOCIALE (aperta il giovedì o nei giorni stabiliti a calendario dalle 21 alle 23)</label>                
            </nz-radio-group>
        </form>
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button type="button" nz-button nzSize="large" nzType="primary" class="me-2" (click)="onIndietro()" *ngIf="i != 0 && !informazione">Indietro</button>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="informazione = false" *ngIf="informazione">Torna ai prodotti</button>
    <span nzTooltipTitle="Selezionare almeno un prodotto per modificare l'ordine! In caso di più clienti, selezionare almeno un prodotto ciascuno!" nz-tooltip nzTooltipPlacement="top" *ngIf="!informazione">
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="onCheckout()" [disabled]="prodotti_selezionati.length <= 0">{{data.dettaglio.length > 1 && i !== data.dettaglio.length - 1 ? 'Prosimo cliente' : 'Conferma' }} ({{totale | currency: 'EUR'}})</button>
    </span>
    <span nzTooltipTitle="Selezionare i dati per le informazioni richieste per poter modificare l'ordine!" nz-tooltip nzTooltipPlacement="top" *ngIf="informazione" >
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="onSubmit()" [disabled]="!formInfo.valid">Conferma</button>
    </span>
</mat-dialog-actions>