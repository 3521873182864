<mat-dialog-content *ngIf="!informazione" #listaProdotti>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Seleziona prodotti per <span style="font-weight: bolder">{{cliente.cognome}} {{cliente.nome}}</span></h5>
    </div>
    <nz-divider></nz-divider>
    <div class="prodotti mb-3 mt-3">        
        <app-caricamento *ngIf="caricamento" style="display: flex; justify-content:center; align-items:center;"> </app-caricamento>
        <nz-list *ngIf="!caricamento">
            <nz-list-item *ngFor="let prodotto of prodotti" class="align-items-center" [ngClass]="{'bg-blue text-white': prodotto.selezionato, 'bg-grey': prodotto.disabilitato || (disable_all && !prodotto.obbligatorio) || (disable_skipass && prodotto.id_categoria == 13)}">
                <ng-container>
                    <nz-list-item-meta *ngIf="prodotto.disabilitato || (disable_all && !prodotto.obbligatorio) || (disable_skipass && prodotto.id_categoria == 13)">
                        <nz-list-item-meta-title>
                            <p class="d-inline px-3">{{prodotto?.nome}} - {{prodotto?.prezzo | currency: 'EUR'}} {{prodotto.stato}}</p>
                        </nz-list-item-meta-title>
                    </nz-list-item-meta>
                    <nz-list-item-meta *ngIf="!prodotto.disabilitato && !(disable_all && !prodotto.obbligatorio) && !(disable_skipass && prodotto.id_categoria == 13)" (click)="!prodotto.selezionato ? onAdd(prodotto) : onRemove(prodotto)" class="cursor-pointer">
                        <nz-list-item-meta-title>
                            <p class="d-inline px-3" [class.text-white]="prodotto.selezionato">{{prodotto?.nome}} - {{prodotto?.prezzo | currency: 'EUR'}} {{prodotto.stato}}</p>
                        </nz-list-item-meta-title>
                    </nz-list-item-meta>
                    <ul nz-list-item-actions class="m-0">
                        <nz-list-item-action>
                            <button nz-button type="button" nzSize="large" class="me-2" *ngIf="!prodotto.disabilitato && !(disable_all && !prodotto.obbligatorio) && !(disable_skipass && prodotto.id_categoria == 13)" nzTooltipTitle="Premere sulla icona della matita, per modificare in modo temporaneo il prezzo del prodotto!" nz-tooltip nzTooltipPlacement="top" (click)="onEdit($event, prodotto)"><i class="bi bi-pencil-square"></i></button>
                            <button nz-button type="button" nzSize="large" class="me-2 bg-grey" *ngIf="prodotto.disabilitato || (disable_all && !prodotto.obbligatorio) || (disable_skipass && prodotto.id_categoria == 13)" [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : (disable_skipass && prodotto.id_categoria == 13) ? 'Aggiungi prima al carrello la Ski Card della vialattea per acquistare i prodotti giornalieri della vialattea!' : 'Prodotto non disponibile')" nz-tooltip nzTooltipPlacement="top"><i class="bi bi-pencil-square"></i></button>
                            
                            <button nz-button type="button" nzSize="large" class="bg-grey" *ngIf="prodotto.disabilitato || (disable_all && !prodotto.obbligatorio) || (disable_skipass && prodotto.id_categoria == 13)" nz-tooltip [nzTooltipTitle]="(disable_all && !prodotto.obbligatorio) ? 'Aggiungi prima al carrello il tesseramento dello Sci Club'  : (prodotto.attivo ? 'Prodotto già acquistato e attivo!' : (disable_skipass && prodotto.id_categoria == 13) ? 'Aggiungi prima al carrello la Ski Card della vialattea per acquistare i prodotti giornalieri della vialattea!' : 'Prodotto non disponibile')"><i class="bi bi-cart-x-fill"></i></button>                            
                            <button nz-button type="button" nzSize="large" (click)="!prodotto.selezionato ? onAdd(prodotto) : onRemove(prodotto)" *ngIf="!prodotto.disabilitato && !(disable_all && !prodotto.obbligatorio) && !(disable_skipass && prodotto.id_categoria == 13)"><i class="bi" [class.bi-cart-dash-fill]="prodotto.selezionato" [class.bi-cart-plus-fill]="!prodotto.selezionato"></i></button>
                        </nz-list-item-action>
                    </ul>
                </ng-container>
            </nz-list-item>
        </nz-list>
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-content *ngIf="informazione">
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Informazioni</h5>
    </div>
    <nz-divider></nz-divider>
    <div class="info mb-3 mt-3">
        <form [formGroup]="formInfo">                    
            <nz-form-item class="flex-column mb-2" *ngIf="informazioni.ritiro">
                <nz-form-label class="text-start" nzRequired>Punti di ritiro: </nz-form-label>
                <nz-form-control>
                    <nz-radio-group formControlName="ritiro">
                        <label *ngFor="let punto_ritiro of punti_ritiro" nz-radio [nzValue]="punto_ritiro.valore">{{punto_ritiro.descrizione}}</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <div class="giornalieri" *ngIf="informazioni.giornalieri.length > 0">
                <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazioni.giornalieri">
                    <nz-form-label class="text-start" nzRequired>Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong></nz-form-label>
                    <nz-form-control>                        
                        <nz-date-picker [formControlName]="'giornaliero_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzDisabledDate]="onDisableDateGiornalieri(prodotto.giorni, prodotto.inizio_validita, prodotto.fine_validita)"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="giornalieri_specifici" *ngIf="informazioni.giornalieri_specifici.length > 0">
                <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazioni.giornalieri_specifici">
                    <nz-form-label class="text-start" nzRequired>Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong></nz-form-label>
                    <nz-form-control>                        
                        <nz-date-picker [formControlName]="'giornaliero_specifico_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzDisabledDate]="onDisableDateGiornalieriSpecifici(prodotto.date_specifiche_dal, prodotto.date_specifiche_al)"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>

<mat-dialog-actions style="float: none; justify-content: space-between;"> 
    <div class="totale" style="display: flex;">
        <h6 class="mb-0" *ngIf="!informazione">Tot cliente: <strong>{{totale | currency: 'EUR'}}</strong> | </h6>
        <h6 class="mb-0 ms-1">Tot carrello: <strong>{{totale_carrello | currency: 'EUR'}}</strong></h6>
    </div>     
    <div class="pulsanti">
        <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
        <button type="button" nz-button nzSize="large" nzType="primary" class="me-2" (click)="onIndietro()" *ngIf="i != 0 && !informazione">Indietro</button>
        <button type="button" nz-button nzSize="large" class="me-2" (click)="onIndietroInformazione()" *ngIf="informazione">Torna ai prodotti</button>
        <span nzTooltipTitle="Selezionare almeno un prodotto per creare l'ordine! In caso di più clienti, selezionare almeno un prodotto ciascuno!" nz-tooltip nzTooltipPlacement="top" *ngIf="!informazione">
            <button type="button" nz-button nzType="primary" nzSize="large" (click)="onCheckout()" [disabled]="checkCarrello() <= 0" >{{data.clienti.length > 1 && i !== data.clienti.length - 1 ? 'Vai a prosimo cliente' : 'Conferma ordine' }}</button>
        </span>
        <span nzTooltipTitle="Selezionare i dati per le informazioni richieste per poter completare l'ordine!" nz-tooltip nzTooltipPlacement="top" *ngIf="informazione" >
            <button type="button" nz-button nzType="primary" nzSize="large" (click)="onSubmit()" [disabled]="!formInfo.valid">Conferma</button>
        </span>
    </div>    
</mat-dialog-actions>