import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-cliente',
  templateUrl: './elimina-cliente.component.html',
  styleUrls: ['./elimina-cliente.component.css']
})
export class EliminaClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private dialogRef: MatDialogRef<EliminaClienteComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }) { }

  ngOnInit(): void {
  }

  eliminaCliente() {

    this.apiService.deleteCliente(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/clienti'])
    })
    
  }

  onClose() {
    this.dialogRef.close(true)
  }
}
