import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { AuthUser, Log } from '../shared/interfacce';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private location: Location, private router: Router) { }

  carica: boolean = true;
  logs: Log[] = [];

  userFilter: any = '';
  descrizioneFilter: any = '';

  ngOnInit(): void {

    // Recupero l'utente loggato
    const userData: AuthUser = this.storageService.getUser();

    // Se il tipo dell'utente è operatore non procedo
    if (userData.tipo == 'Operatore') {
      this.router.navigate(['/home']);
    }

    // Recupero i logs
    this.apiService.getLogs().subscribe({
      next: (data) => this.logs = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.carica = false
    })

  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamentoUtente(a: Log, b: Log) {    
    return a.username.localeCompare(b.username)
  }

  onOrdinamentoDescrizione(a: Log, b: Log) {    
    return a.descrizione.localeCompare(b.descrizione)
  }

  onOrdinamentoData(a: Log, b: Log) {    
    return a.data.toString().localeCompare(b.data.toString())
  }

  onLog(log: Log) {

    // Se la descrizione contiene "aggiornamento" o "creazione" metto il router al click
    if ((log.descrizione.startsWith('Aggiornamento') || log.descrizione.startsWith('Creazione')) && log.id_operazione) {
      this.router.navigate([log.categoria + "/" + log.id_operazione]);
    }    
  }
}
