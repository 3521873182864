<div class="p-3">
  <ng-template #tesseramentiTitle>
    <div class="row p-md-3 p-1">
      <div class="col-lg-1 mt-2">
        <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;"
            class="bi bi-arrow-left"></i></button>
      </div>
      <div class="col-lg-8 mt-2">
        <div class="row">
          <div class="col-md-4">
            <input nz-input placeholder="Cerca Cliente" nzSize="large" [(ngModel)]="clienteFilter" />
          </div>
          <div class="col-md-4 mt-md-0 mt-3">
            <input nz-input placeholder="Cerca Prodotto" nzSize="large"  [(ngModel)]="prodottoFilter" />
          </div>
          <div class="col-md-4 mt-md-0 mt-3">            
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Cerca Stato" [(ngModel)]="statoFilter" nzSize="large" class="w-100">
              <nz-option nzValue="valido" nzLabel="Stato: Valido"></nz-option>
              <nz-option nzValue="attivazione" nzLabel="Stato: In Attivazione"></nz-option>
              <nz-option nzValue="scaduto" nzLabel="Stato: Scaduto"></nz-option>
              <nz-option nzValue="" nzLabel="Stato: Tutti"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mt-2" style="text-align: right;">        
        <nz-button-group class="w-100">
          <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea ordine</button>
          <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
            <span nz-icon nzType="ellipsis"></span>
          </button>
        </nz-button-group>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item class="p-3" (click)="onExportGiornalieri()">Esportazione giornaliera <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
            <li nz-menu-item class="p-3" (click)="onExport()">Esportazione filtrata <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </ng-template>
  <nz-card [nzTitle]="tesseramentiTitle">
    <div class="row p-md-3 p-1">
      <p class="text-muted d-flex justify-content-between small">
        <span>
          <span class="text-muted text-decoration-none"><i class="bi bi-info-circle"></i> Legenda: </span>
          <span class="ms-3">            
            <span class="text-danger me-3"><i class="bi bi-circle-fill mx-2"></i>Dettaglio ordine scaduto</span>
            <span class="text-warning me-3"><i class="bi bi-circle-fill mx-2"></i>Dettaglio ordine in attivazione</span>
          </span>
        </span>
      </p>
      <nz-table #tesseramentiTable 
        *ngIf="!carica" 
        nzShowSizeChanger
        [nzTotal]="totale"
        [nzData]="tesseramenti"
        nzNoResult="Nessun Dettaglio ordine trovato"
        [nzPageSize]="pageSize"
        [nzFrontPagination]="false"
        [nzLoading]="loading"             
        [nzPageIndex]="pageIndex"
        [nzBordered]="true" 
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        (nzQueryParams)="onQueryParamsChange($event)">
        <thead>
          <tr>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="tesseramenti.data_creazione" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="clienteFilter" class="text-start">Data Ordine</th>            
            <th [nzSortFn]="onOrdinamento" nzColumnKey="cognome" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="prodottoFilter" class="text-start">Cliente</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="dats_nascita" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="statoFilter" class="text-center">Data di nascita</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="prodotto" class="text-start">Prodotto</th>            
            <th [nzSortFn]="onOrdinamento" nzColumnKey="tesseramenti.fine_validita" class="text-end">Fine Validità</th>            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tesseramento of tesseramentiTable.data" [routerLink]="['/tesseramenti/', tesseramento.id]" class="cursor-pointer" [ngClass]="{'bg-danger': tesseramento.stato == 'scaduto', 'bg-warning text-dark': tesseramento.stato == 'attivazione' }">
            <td class="text-start">{{tesseramento.data_creazione | date: 'dd/MM/yyyy'}}</td>
            <td class="text-start">{{tesseramento.cognome + ' ' + tesseramento.nome}} </td>
            <td class="text-center">{{tesseramento.data_nascita | date: 'dd/MM/yyyy'}}</td>
            <td class="text-start">{{tesseramento.prodotto}}</td>            
            <td class="text-end">{{tesseramento.fine_validita | date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </nz-table>

      <app-caricamento *ngIf="carica" style="display: flex; justify-content:center; align-items:center;">
      </app-caricamento>
    </div>
  </nz-card>
</div>