<div class="p-3">
    <ng-template #prodottiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col-md-6">
                        <input nz-input placeholder="Cerca Nome" nzSize="large" [(ngModel)]="nomeFilter" />
                    </div>
                    <div class="col-md-6 mt-md-0 mt-3">
                        <input nz-input placeholder="Cerca Descrizione" nzSize="large"
                            [(ngModel)]="descrizioneFilter" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">                
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()" *ngIf="!seleziona">Crea un prodotto</button>
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()" *ngIf="seleziona" [disabled]="setOfCheckedId.size <= 0">Modifica prodotti</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                      <span nz-icon nzType="ellipsis"></span>
                    </button>
                  </nz-button-group>                
                  <nz-dropdown-menu #menu1="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item class="p-3" *ngIf="!seleziona" (click)="onSeleziona()">Seleziona i prodotti che vuoi modificare <i class="ms-2 bi bi-check2-square"></i></li>
                            <li nz-menu-item class="p-3" *ngIf="seleziona" (click)="onDeseleziona()">Esci dalla selezione <i class="ms-2 bi bi-check2-square"></i></li>
                        </ul>
                  </nz-dropdown-menu>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="prodottiTitle">
        <div class="row p-md-3 p-1">
            <p class="text-muted d-flex justify-content-between small">
                <span>
                    <span class="text-muted text-decoration-none">
                        <i class="bi bi-info-circle"></i> Legenda:
                    </span>
                    <span class="ms-5">
                        <span class="text-muted me-3"><i class="bi bi-circle mx-2"></i>Prodotto attivo</span>
                        <span class="text-danger me-3"><i class="bi bi-circle-fill mx-2"></i>Prodotto scaduto</span>
                    </span>
                </span>
            </p>
            <nz-table *ngIf="!carica" #prodottiTable nzShowPagination
                [nzTotal]="(prodotti | searchProdottiNome: nomeFilter | searchProdottiDescrizione: descrizioneFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="prodotti | searchProdottiNome: nomeFilter | searchProdottiDescrizione: descrizioneFilter" 
                [nzBordered]="true" nzNoResult="Nessun prodotto trovato" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
                <thead>
                    <tr>
                        <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)" *ngIf="seleziona"></th>
                        <th [nzSortFn]="onOrdinamentoNome">Nome</th>
                        <th [nzSortFn]="onOrdinamentoCategoria" class="text-center">Categoria</th>
                        <th [nzSortFn]="onOrdinamentoDescrizione" class="text-center">Descrizione</th>
                        <th [nzSortFn]="onOrdinamentoPrezzo" class="text-center">Prezzo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prodotto of prodottiTable.data | searchProdottiNome: nomeFilter | searchProdottiDescrizione: descrizioneFilter"
                        [routerLink]="!seleziona ? ['/prodotti/', prodotto.id] : null" class="cursor-pointer" 
                        [ngClass]="{'bg-danger':  dateDiff(prodotto.fine_validita) > 0 && prodotto.giornaliero == false}">
                        <td [nzChecked]="setOfCheckedId.has(prodotto.id)" (nzCheckedChange)="onItemChecked(prodotto.id, $event)" *ngIf="seleziona"></td>
                        <td>{{prodotto.nome}} </td>
                        <td class="text-center">{{prodotto.categoria}}</td>
                        <td class="text-center">{{prodotto.descrizione}}</td>
                        <td class="text-center">{{prodotto.prezzo | currency: 'EUR'}}</td>
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" style="display: flex; justify-content:center; align-items:center;">
            </app-caricamento>
        </div>
    </nz-card>
</div>