import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { AuthUser, Utente } from '../shared/interfacce';
import { AggiungiUtenteComponent } from './aggiungi-utente/aggiungi-utente.component';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.css']
})
export class UtentiComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private dialog: MatDialog, private location: Location, private router: Router) { }

  utenti: Utente[] = [];

  carica: boolean = true;

  usernameFilter: any = '';
  tipoFilter: any = '';

  ngOnInit(): void {

    // Recupero l'utente loggato
    const userData: AuthUser = this.storageService.getUser();

    // Se il tipo dell'utente è operatore non procedo
    if (userData.tipo == 'Operatore') {
      this.router.navigate(['/home']);
    }

    // Recupero gli utenti
    this.apiService.setUtenti();
    this.apiService.utentiChanged.subscribe({
      next: (data) => {
        this.utenti = data;
        this.carica = false
      },
      error: (err) => this.apiService.Error(err.error.message)      
    })

  }

  onOrdinamentoUsername(a: Utente, b: Utente) {
    return a.username.localeCompare(b.username)
  }

  onOrdinamentoTipo(a: Utente, b: Utente) {
    return a.tipo.localeCompare(b.tipo)
  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiUtenteComponent, { autoFocus: false, width: '1000px' })
  }

  onIndietro() {
    this.location.back();
  }

}
