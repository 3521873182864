import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-immagine-prodotto',
  templateUrl: './elimina-immagine-prodotto.component.html',
  styleUrls: ['./elimina-immagine-prodotto.component.css']
})
export class EliminaImmagineProdottoComponent {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EliminaImmagineProdottoComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number, immagine: string }) { }


  onElimina() {

    // Elimino l'immagine del prodotto
    this.apiService.deleteImmagineProdotto(this.data.id, this.data.immagine).subscribe({
      next: (data) => {},
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }

}
