import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { PuntoRitiro } from '../shared/interfacce';
import { AggiungiPuntoRitiroComponent } from './aggiungi-punto-ritiro/aggiungi-punto-ritiro.component';

@Component({
  selector: 'app-punti-ritiro',
  templateUrl: './punti-ritiro.component.html',
  styleUrls: ['./punti-ritiro.component.css']
})
export class PuntiRitiroComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  punti_ritiro: PuntoRitiro[] = [];

  descrizioneFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    // Recupero i punti di ritiro
    this.apiService.setPuntiRitiro();
    this.apiService.puntiRitiroChanged.subscribe({
      next: (data) => { this.punti_ritiro = data; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onOrdinamentoValore(a: PuntoRitiro, b: PuntoRitiro) {
    return a.valore.localeCompare(b.valore)
  }

  onOrdinamentoDescrizione(a: PuntoRitiro, b: PuntoRitiro) {
    return a.descrizione.localeCompare(b.descrizione)
  }

  onIndietro() {
    this.location.back();
  }

  onAdd() {
    this.dialog.closeAll()
    this.dialog.open(AggiungiPuntoRitiroComponent, { autoFocus: false, width: '1000px' });
  }

}
