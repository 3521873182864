import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Location } from '@angular/common';
import { Configurazione } from '../shared/interfacce';
import { AggiungiConfigurazioneComponent } from './aggiungi-configurazione/aggiungi-configurazione.component';

@Component({
  selector: 'app-configurazioni',
  templateUrl: './configurazioni.component.html',
  styleUrls: ['./configurazioni.component.css']
})
export class ConfigurazioniComponent implements OnInit {

  constructor(private dialog: MatDialog, private location: Location, private apiService: ApiService) { }

  configurazioni: Configurazione[] = [];

  descrizioneFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    this.apiService.setConfigurazioni();
    this.apiService.configurazioniChanged.subscribe({
      next: (data) => { this.configurazioni = data; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamentoDescrizione(a: Configurazione, b: Configurazione) {
    return (a.descrizione || '').localeCompare((b.descrizione || ''))
  }

  onOrdinamentoValore(a: Configurazione, b: Configurazione) {
    return a.valore.localeCompare(b.valore)
  }

  onAdd() {

    this.dialog.closeAll();
    this.dialog.open(AggiungiConfigurazioneComponent, { autoFocus: false, width: '1000px' })

  }

}
