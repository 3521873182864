import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-contatto',
  templateUrl: './elimina-contatto.component.html',
  styleUrls: ['./elimina-contatto.component.css']
})
export class EliminaContattoComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EliminaContattoComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number, id_cliente: number }) { }

  ngOnInit(): void {

  }

  onElimina() {

    this.apiService.deleteContatto(this.data.id).subscribe({
      next: (data) => this.apiService.setContatti(this.data.id_cliente),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })
    
  }

  onClose() {
    this.dialogRef.close(false)
  }

}
