import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-aggiungi-utente',
  templateUrl: './aggiungi-utente.component.html',
  styleUrls: ['./aggiungi-utente.component.css']
})
export class AggiungiUtenteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiUtenteComponent>, private apiService: ApiService, private router: Router) { }

  formUtente!: FormGroup;

  hide: boolean = true;

  error_messages = {
    'username': [
      { type: 'required', message: "Inserire un username per procedere con la creazione di un utente"},      
    ],
    'password': [
      { type: 'required', message: "Inserire una password per procedere con la creazione di un utente"},      
      { type: 'minlength', message: "Inserire almeno 3 caratteri"},      
    ],    
    'tipo': [
      { type: 'required', message: "Inserire un tipo per procedere con la creazione di un utente"},      
    ]
  }

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formUtente = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      tipo: new FormControl(null, Validators.required)
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let utente = {
      username: this.formUtente.get('username')?.value,
      password: this.formUtente.get('password')?.value,
      tipo: this.formUtente.get('tipo')?.value
    }

    // Aggiungo l'utente
    this.apiService.addUtente(utente).subscribe({
      next: (data) => this.apiService.setUtenti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })


  }

  onClose() {
    this.dialogRef.close(false);
  }

}
