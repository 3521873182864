import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Carrello, CheckOut, Cliente, DettaglioOrdineCliente, FasceProdotti, Prodotto, Promozione, Tesseramento } from 'src/app/shared/interfacce';
import { ModificaProdottiTesseramentiComponent } from 'src/app/tesseramenti/modifica-prodotti-tesseramenti/modifica-prodotti-tesseramenti.component';

@Component({
  selector: 'app-modifica-prodotti-storico-ordine',
  templateUrl: './modifica-prodotti-storico-ordine.component.html',
  styleUrls: ['./modifica-prodotti-storico-ordine.component.css']
})
export class ModificaProdottiStoricoOrdineComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaProdottiStoricoOrdineComponent>, @Inject(MAT_DIALOG_DATA) public data: { dettaglio: DettaglioOrdineCliente[] }, private apiService: ApiService, private dialog: MatDialog) { }

  totale: number = 0;
  eta: number = 0;
  i: number = 0;

  prodotti_selezionati: Prodotto[] = [];
  prodotti: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  promozioni: Promozione[] = [];
  cliente?: Cliente;
  fascia?: FasceProdotti;

  disable_all!: boolean;
  disalbe_all_force!: boolean;
  informazione: boolean = false

  carrello: Carrello[] = [];
  checkout: CheckOut = { note: '', carrello: this.carrello, totale: 0 };

  formInfo!: FormGroup;

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formInfo = new FormGroup({
      ritiro: new FormControl(null, Validators.required)
    })

    this.getData();
  }

  getData() {

    // Recupero il primo cliente passato al modal
    this.cliente = this.data.dettaglio[this.i].cliente;

    // Calcolo l'età del cliente
    let differenza = Math.abs(Date.now() - new Date(this.cliente.data_nascita).getTime());
    this.eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

    // Recupero i prodotti
    this.apiService.getProdotti().subscribe({
      next: (data) => this.prodotti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        // Recupero le promozioni        
        this.apiService.getPromozioni().subscribe({
          next: (data) => this.promozioni = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {
            // Recupero i tesseramenti del cliente
            this.apiService.getTesseramentiCliente(this.cliente!.id).subscribe({
              next: (data) => this.tesseramenti = data,
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {

                // Se il cliente non ha nessun tesseramento disabilito tutti i prodotti
                this.disable_all = this.tesseramenti.length <= 0 ? true : false;
                
                this.getPrezzi();
                this.getProdottiOrdine()
              }
            })
          }
        })  
      }
    })
    
  }

  getPrezzi() {

    this.disable_all = true;
    
    // Ciclo tra i prodotti
    for (let prodotto of this.prodotti) {

      // Controllo se la data e l'orario sono validi
      let prodotto_validita = prodotto.giornaliero ? this.dateCheck(prodotto.giorni) : this.dateDiff(prodotto.inizio_validita, prodotto.fine_validita);
      let prodotto_orario_validita = this.orarioCheck(prodotto.inizio_orario, prodotto.fine_orario, prodotto.inizio_validita, prodotto.fine_validita, prodotto.giornaliero, prodotto.giorni);

      // Se il prodotto è attivo
      if (prodotto_validita == 1 && prodotto_orario_validita == 1) {

        this.apiService.getFasceProdotto(prodotto.id, this.eta).subscribe({
          next: (data) => this.fascia = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            if (this.fascia) {
              prodotto.prezzo = this.fascia.prezzo;
              prodotto.nome = prodotto.nome + " - " + this.fascia.descrizione;
            }

            // Ciclo tra le promozioni promozioni
          for (let promozione of this.promozioni) {

            // Se trovo il match tra la promozione e il prodotto
            if (prodotto.id == promozione.id_prodotto) {

              // Controllo la validità della data e dell'orario della promozione
              let promo_validita = promozione.giornaliero ? this.dateCheck(promozione.giorni) : this.dateDiff(promozione.inizio_validita, promozione.fine_validita);
              let promo_orario_validita = this.orarioCheck(promozione.inizio_orario, promozione.fine_orario, promozione.inizio_validita, promozione.fine_validita, promozione.giornaliero, promozione.giorni);

              // Se la promozione è valida la applico al prezzo del prodotto
              if (promo_validita == 1 && promo_orario_validita == 1) {

                prodotto.disabilitato = false;
                prodotto.stato = '- (PROMO)';
                prodotto.prezzo = promozione.percentuale ? prodotto.prezzo + (prodotto.prezzo * (promozione.sconto / 100)) : prodotto.prezzo + promozione.sconto;
              }

            }
          }

          // Ciclo tra i tesseramenti del cliente
          for (let tesseramento of this.tesseramenti) {

            // se trovo il match disabilito il prodotto
            if (tesseramento.id_prodotto == prodotto.id) {

              // controllo se il tesseramento è valido
              let tesseramento_validita = this.dateDiff(tesseramento.inizio_validita, tesseramento.fine_validita);
              if (tesseramento_validita == 1 && tesseramento_validita == 1) {

                prodotto.disabilitato = true;
                prodotto.stato = '- (ACQUISTATO E VALIDO)'
                prodotto.attivo = true;

                if (prodotto.obbligatorio) {
                  this.disable_all = false;
                }
              }

            }

          }
          }
        })

      } else {

        // Se il prodotto non è attivo lo disabilito e gli aggiungo lo stato
        if (prodotto_validita == 1 && prodotto_orario_validita == 2) {
          prodotto.stato = '- (NON ATTIVO)';
          prodotto.disabilitato = true;
        }
        else if (prodotto_validita == 2 && prodotto_orario_validita == 1) {
          prodotto.stato = '- (NON ATTIVO)';
          prodotto.disabilitato = true;
        }
        else if (prodotto_validita == 2 && prodotto_orario_validita == 2) {
          prodotto.stato = '- (NON ATTIVO)';
          prodotto.disabilitato = true;
        } else {
          prodotto.stato = '- (SCADUTO)';
          prodotto.disabilitato = true;
        }
      }      

    }
  }


  getProdottiOrdine() {

    for (let prodotto_ordine of this.data.dettaglio[this.i].prodotti) {

      for (let prodotto of this.prodotti) {

        if (prodotto.id == prodotto_ordine.id) {

          this.disable_all = false;
          this.disalbe_all_force = false;

          prodotto.disabilitato = false;
          prodotto.selezionato = true;

          this.prodotti_selezionati.push(prodotto);
          this.totale += +prodotto.prezzo

        }

      }
    }

  }

  getInfo() {
    //ciclo nel carrello
    for (let carrello of this.carrello) {

      //ciclo nei prodotti
      for (let prodotto of carrello.prodotti) {

        //se un prodotto è delle categoria della Vialattea o SkipassOpen
        if (prodotto.informativo) {
          this.informazione = true;
        }

      }

    }
  }

  onAdd(prodotto: Prodotto) {
    this.prodotti_selezionati.push(prodotto);
    prodotto.selezionato = true;
    this.totale = 0;

    // ciclo per i prodotti
    for (let prodotto of this.prodotti) {
      // ciclo tre i prodotti selezionati
      for (let prodotto_selezionato of this.prodotti_selezionati) {
        // se trovo il match        
        if (prodotto_selezionato.id == prodotto.id) {
          this.totale += prodotto_selezionato.prezzo;

          if (prodotto.obbligatorio) {
            this.disable_all = false;
          }
        }

      }
    }
  }

  onRemove(prodotto: Prodotto) {

    const index = this.prodotti_selezionati.indexOf(prodotto, 0);
    if (index > -1) {
      this.prodotti_selezionati.splice(index, 1);
    }

    if (prodotto.obbligatorio) {
      this.disable_all = true;

      for (let prodotto of this.prodotti) {
        prodotto.selezionato = false
      }
    }

    prodotto.selezionato = false;
    this.totale = 0;

    // ciclo per i prodotti selzezionati
    for (let prodotto_selezionato of this.prodotti_selezionati) {
      this.totale += prodotto_selezionato.prezzo;
    }
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
    var dialogRef = this.dialog.open(ModificaProdottiTesseramentiComponent, { autoFocus: false, data: { prodotto: item }, width: '750px' });
    dialogRef?.afterClosed().subscribe((data) => {

      if (data) {
        item.prezzo = data.prezzo;
      }

    })
  }

  onCheckout() {
    this.carrello.push({ totale: this.totale, prodotti: this.prodotti_selezionati, id_cliente: this.cliente!.id, nome: this.cliente!.nome, cognome: this.cliente!.cognome });
    if (this.data.dettaglio.length > 1 && this.i !== this.data.dettaglio.length - 1) {
      this.i++;
      this.totale = 0;
      this.prodotti_selezionati = [];
      this.getData();
    } else {

      this.getInfo();
      if (!this.informazione) {
        this.checkout.carrello = this.carrello
        this.checkout.totale = this.totale;
        this.dialogRef.close(this.checkout);
      }
    }
  }

  onSubmit() {
    let ritiro = this.formInfo.get('ritiro')?.value;
    this.checkout.note = 'Punto di ritiro: ' + ritiro;
    this.checkout.carrello = this.carrello;    
    this.checkout.totale = this.totale;
    this.dialogRef.close(this.checkout);
  }

  onIndietro() {
    this.i--;
    this.totale = 0;
    this.prodotti_selezionati = [];
    this.carrello.splice(this.i, 1);
    this.getData();
  }

  dateDiff(inizio_validita: Date, fine_validita: Date) {
    let todayDate = new Date();
    let fineDate = new Date(fine_validita);
    let inizioDate = new Date(inizio_validita);

    inizioDate.setDate(inizioDate.getDate());
    let diffInizio = todayDate.getTime() - inizioDate.getTime();
    let diffGiorniInizio = Math.floor(diffInizio / (1000 * 3600 * 24));

    fineDate.setDate(fineDate.getDate());
    let diffFine = todayDate.getTime() - fineDate.getTime();
    let diffGiorniFine = Math.floor(diffFine / (1000 * 3600 * 24));

    return diffGiorniInizio < 0 ? 2 : diffGiorniInizio >= 0 && diffGiorniFine <= 0 ? 1 : 0;
  }

  dateCheck(giorni: any) {
    let today = new Date().toLocaleString('it-IT', { weekday: 'long' });
    let result = 0;

    for (let giorno of JSON.parse(giorni)) {
      if (giorno.toLowerCase() == today.toLowerCase()) {
        result = 1;
        break;
      }
    }
    return result;

  }

  orarioCheck(inizio_orario: string, fine_orario: string, inizio_validita: Date, fine_validita: Date, giornaliero: boolean, giorni: any) {
    let result = 0;

    let giorno = new Date().toLocaleString('it-IT', { weekday: 'long' });
    let giorni_validi = giornaliero ? JSON.parse(giorni) : [];
    let oggi = new Date();
    let inizio_date = new Date(inizio_validita);
    let fine_date = new Date(fine_validita);

    let startDate = giornaliero ? new Date(oggi.getTime()) : new Date(inizio_date.getTime());
    if (inizio_orario) {
      startDate.setHours(+inizio_orario.split(":")[0]);
      startDate.setMinutes(+inizio_orario.split(":")[1]);
      startDate.setSeconds(0);
    } else {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
    }

    let endDate = giornaliero ? new Date(oggi.getTime()) : new Date(fine_date.getTime());
    if (fine_orario) {
      endDate.setHours(+fine_orario.split(":")[0]);
      endDate.setMinutes(+fine_orario.split(":")[1]);
      endDate.setSeconds(0);
    } else {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
    }


    if (startDate < oggi && endDate > oggi) {
      result = 1;
    }
    if (startDate > oggi) {
      result = 2;
    }

    for (let giorno_valido of giorni_validi) {
      if (giorno_valido.toLowerCase() == giorno.toLowerCase()) {
        if (startDate < oggi && endDate > oggi) {
          result = 1;
          break;
        }
      }
    }

    return result;

  }

  onClose() {
    this.dialogRef.close(false);
  }

}
