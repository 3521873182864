import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Promozione } from '../shared/interfacce';
import { AggiungiPromozioneComponent } from './aggiungi-promozione/aggiungi-promozione.component';

@Component({
  selector: 'app-promozioni',
  templateUrl: './promozioni.component.html',
  styleUrls: ['./promozioni.component.css']
})
export class PromozioniComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  promozioni: Promozione[] = [];

  nomeFilter: any = '';
  prodottoFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    // Recupero le promozioni
    this.apiService.setPromozioni();
    this.apiService.promozioneChanged.subscribe({
      next: (data) => { this.promozioni = data; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  dateDiff(fine_validita: string) {

    let todayDate = new Date();
    let fineDate = new Date(fine_validita);
    fineDate.setDate(fineDate.getDate());
    let differenceInTime = todayDate.getTime() - fineDate.getTime();

    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  }

  onOrdinamentoNome(a: Promozione, b: Promozione) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoProdotto(a: Promozione, b: Promozione) {
    return a.nome_prodotto.localeCompare(b.nome_prodotto)
  }

  onOrdinamentoPrezzo(a: Promozione, b: Promozione) {
    return a.sconto - b.sconto;
  }

  onIndietro() {
    this.location.back();
  }

  onAdd() {
    this.dialog.closeAll()
    this.dialog.open(AggiungiPromozioneComponent, { autoFocus: false, width: '1000px' });
  }

}
