import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Movimenti } from 'src/app/shared/interfacce';
import { ModificaMovimentoComponent } from '../modifica-movimento/modifica-movimento.component';
import { EliminaMovimentoComponent } from '../elimina-movimento/elimina-movimento.component';

@Component({
  selector: 'app-dettagli-movimento-cassa',
  templateUrl: './dettagli-movimento-cassa.component.html',
  styleUrls: ['./dettagli-movimento-cassa.component.css']
})
export class DettagliMovimentoCassaComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  id!: number;  
  
  movimento?: Movimenti;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.getData();
    });
  }

  getData() {

    this.apiService.getMovimento(this.id).subscribe({
      next: (data) => this.movimento = data,
      error: (err) => this.router.navigate(['/movimenti/cassa']),
      complete: () => { }
    })

  }

  onIndietro() {
    this.router.navigate(['/movimenti/cassa'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaMovimentoComponent, { data: { id: this.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaMovimentoComponent, { data: { id: this.id }, autoFocus: false, width: '750px' })
  }

}
