import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Movimenti, Ordine } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-movimento',
  templateUrl: './modifica-movimento.component.html',
  styleUrls: ['./modifica-movimento.component.css']
})
export class ModificaMovimentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaMovimentoComponent>, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private dataPipe: DatePipe, private router: Router) { }

  formMovimento!: FormGroup;

  ordini: Ordine[] = [];
  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = [];

  movimento?: Movimenti;

  error_messages = {
    'descrizione': [
      { type: 'required', message: 'Inserire una descrizione per procedere con la creazione di un movimento' }
    ],
    'tipo': [
      { type: 'required', message: 'Inserire un conto per procedere con la creazione di un movimento' }
    ],
    'pagato': [
      { type: 'required', message: 'Inserire il prezzo pagato per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'residuo': [
      { type: 'required', message: 'Inserire un prezzo residuo per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'totale': [
      { type: 'required', message: 'Inserire un prezzo totale per procedere con la creazione di un movimento' },
      { type: 'pattern', message: 'Inserire un prezzo valido!' }
    ],
    'data': [
      { type: 'required', message: 'Inserire una data per procedere con la creazione di un movimento' },
    ]
  }

  ngOnInit(): void {
    this.formMovimento = new FormGroup({
      tipo: new FormControl(null, Validators.required),
      metodo: new FormControl(null, Validators.required),
      descrizione: new FormControl(null, Validators.required),
      cliente: new FormControl(null),
      ordine: new FormControl(null),
      pagato: new FormControl(null, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.required]),
      residuo: new FormControl(null, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
      totale: new FormControl(null, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.required]),
      data: new FormControl(null)
    })

    let url = this.router.url;
    if (url.indexOf('cassa') >= 0) {
      this.formMovimento.get('metodo')?.disable()
    }

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        // Filtro i clienti fino a 10
        this.filteredClienti = this.clienti.slice(0, 10)

        // Recupero il movimento
        this.apiService.getMovimento(this.data.id).subscribe({
          next: (data) => this.movimento = data,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {
            
            // Setto i valori del movimento nel form
            this.formMovimento.setValue({
              tipo: this.movimento?.tipo,
              metodo: this.movimento?.metodo,
              descrizione: this.movimento?.descrizione,
              cliente: this.movimento?.id_cliente,
              ordine: null,
              pagato: this.movimento?.pagato,
              residuo: this.movimento?.residuo,
              totale: this.movimento?.totale,
              data: this.movimento?.data_creazione
            })     
          }
        })
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }

  filtra(name: string): Cliente[] {
    const filterValue = name?.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.filtra(ricerca).slice(0, 10);
  }

  onTipo(tipo: string) {    
    if (tipo == 'Incasso') {

      let cliente = this.formMovimento.get('cliente')?.value;

      if (cliente != null) {
        this.formMovimento.get('ordine')?.enable();
      }
    } else {

      this.formMovimento.patchValue({
        ordine: null
      })

      this.formMovimento.get('ordine')?.disable();


    }
  }

  onCliente(id_cliente: number) {

    // Se selezionato un cliente procedo
    if (id_cliente) {

      // Abilito l'input degli ordini e pulisco il valore al suo interno
      let tipo = this.formMovimento.get('tipo')?.value;

      if (tipo == 'Incasso') {

        // Abilito l'input degli ordini e pulisco il valore al suo interno
        this.formMovimento.get('ordine')?.enable();
      }

      this.formMovimento.patchValue({ ordine: null })


      // Recupero gli ordini del cliente
      this.apiService.getOrdiniCliente(id_cliente).subscribe(ordini => {

        // Recupero gli ordini del cliente non pagati
        this.ordini = ordini.filter((ordine: Ordine) => ordine.stato?.toLowerCase() == 'non pagato' || ordine.id == this.movimento?.id_ordine);

        if (this.movimento?.id_ordine) {
          // Recupero l'ordine associato e gli rimetto il prezzo che aveva prima
          let ordine_associato = ordini.filter((ordine: Ordine) => ordine.id == this.movimento!.id_ordine)[0];
          ordine_associato.prezzo = ordine_associato.prezzo + this.movimento!.pagato;
          this.formMovimento.patchValue({ ordine: ordine_associato })

          this.formMovimento.get('ordine')?.disable();
          this.formMovimento.get('cliente')?.disable();
        }        

      }, err => {
        this.apiService.Error(err.error.message);
      })

    } else {

      // Se è stato rimosso il cliente blocco l'input e lo pulisco
      this.ordini = [];
      this.formMovimento.get('ordine')?.disable();
      this.formMovimento.patchValue({ ordine: null })
    }
  }

  onOrdine(ordine: Ordine) {

    // Se c'è un ordine proseguo
    if (ordine) {

      if (this.movimento!.pagato != 0) {
        this.formMovimento.patchValue({ totale: this.movimento!.totale.toFixed(2), pagato: this.movimento!.pagato.toFixed(2), residuo: this.movimento!.residuo.toFixed(2)})      
      } else {
        // Salvo i dati ottenuti nel form
        this.formMovimento.patchValue({ totale: ordine.prezzo.toFixed(2), pagato: ordine.prezzo.toFixed(2), residuo: '0.00' })
      }

    } else {

      // Salvo i dati ottenuti nel form
      this.formMovimento.patchValue({ totale: '0.00', pagato: '0.00', residuo: '0.00' })
    }
  }

  onTotale(value: string) {

    let pagato = this.formMovimento.get('pagato')?.value;

    this.formMovimento.patchValue({ residuo: (pagato - +value).toFixed(2) })

  }

  onPagato(value: string) {

    let totale = this.formMovimento.get('totale')?.value;

    this.formMovimento.patchValue({ residuo: (totale - +value).toFixed(2) })

  }

  onSubmit() {

    let movimento = {
      tipo: this.formMovimento.get('tipo')?.value,
      metodo: this.formMovimento.get('metodo')?.value,
      descrizione: this.formMovimento.get('descrizione')?.value,
      id_cliente: this.formMovimento.get('cliente')?.value ? this.formMovimento.get('cliente')?.value : null,
      id_ordine: this.formMovimento.get('ordine')?.value ? this.formMovimento.get('ordine')?.value.id : null,
      pagato: this.formMovimento.get('pagato')?.value || 0.00,
      residuo: this.formMovimento.get('residuo')?.value || 0.00,
      totale: this.formMovimento.get('totale')?.value || 0.00,
      data: this.dataPipe.transform(this.formMovimento.get('data')?.value, 'yyyy-MM-dd')
    }

    this.apiService.updateMovimento(this.data.id, movimento).subscribe({
      next: (data) => this.apiService.setMovimenti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

}
