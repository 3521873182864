import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-utente-password',
  templateUrl: './modifica-utente-password.component.html',
  styleUrls: ['./modifica-utente-password.component.css']
})
export class ModificaUtentePasswordComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaUtentePasswordComponent>) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(false);
  }

  onAccept() {
    this.dialogRef.close(true);
  }
}
