import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Configurazione } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-configurazione',
  templateUrl: './modifica-configurazione.component.html',
  styleUrls: ['./modifica-configurazione.component.css']
})
export class ModificaConfigurazioneComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaConfigurazioneComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService) { }

  formConfigurazione!: FormGroup;

  configurazione?: Configurazione;

  error_messages = {
    'valore': [
      { type: 'required', message: "Inserire un valore per procedere con la modifica di un codice sconto" },
    ]
  }

  ngOnInit(): void {

    this.formConfigurazione = new FormGroup({
      descrizione: new FormControl(null),
      valore: new FormControl(null, Validators.required)
    })

    this.apiService.getConfigurazione(this.data.id).subscribe({
      next: (data) => this.configurazione = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.formConfigurazione.setValue({
          descrizione: this.configurazione?.descrizione,
          valore: this.configurazione?.valore,
        })
      }
    }) 

  }

  onSubmit() {
    let configurazione = {
      descrizione: this.formConfigurazione.get('descrizione')?.value || '',
      valore: this.formConfigurazione.get('valore')?.value,
    }

    this.apiService.updateConfigurazione(this.data.id, configurazione).subscribe({
      next: (data) => this.apiService.setConfigurazioni(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    }) 

  }

  onClose() {
    this.dialogRef.close();
  }

}
