<div class="p-3">
    <ng-template #prodottiTitle>        
        <div class="row p-md-3 p-1">
            <div class="col-lg-4 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-4">                
            </div>
            <div class="col-lg-4 mt-2" style="text-align: right;">
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" [disabled]="setOfCheckedId.size <= 0" (click)="onAttiva()">Attiva le Card Vialattea</button>
                    <button nz-button nzSize="large" class="" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onExport()">Esporta le Card Vialattea <i class="ms-2 bi bi-file-earmark-person-fill"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">
                <input nz-input placeholder="Cerca Cliente" nzSize="large" [(ngModel)]="clienteFilter" />
            </div>
            <div class="col-md-6 mt-md-0 mt-3">
                <nz-range-picker class="w-100" nzSize="large" [(ngModel)]="dataFilter" [nzPlaceHolder]="['Data di inizio Ordine', 'Data di fine Ordine']" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>
            </div>            
        </div>
        <div class="row p-md-3 p-1">            
            <div class="col-md-6 text-center">
                <label nz-checkbox [(ngModel)]="pagatiFilter">Mostra solo Card Vialattea NON PAGATE</label>       
            </div>
            <div class="col-md-6 mt-md-0 mt-3 text-center">
                <label nz-checkbox [(ngModel)]="attiviFilter">Mostra solo Card Vialattea NON ATTIVATE</label>       
            </div>            
        </div>        
    </ng-template>
    <nz-card [nzTitle]="prodottiTitle">
        <div class="row p-md-3 p-1">
            <div class="col text-center">
                <h2 class="fw-bold">Elenco Ski Card Vialattea</h2>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <p class="text-muted d-flex justify-content-between small">
                <span>
                    <span class="text-muted text-decoration-none">
                        <i class="bi bi-info-circle"></i> Legenda:
                    </span>
                    <span class="ms-5">
                        <span class="text-gray me-3"><i class="bi bi-circle-fill mx-2"></i>Card Vialattea Attivate</span>
                        <span class="text-danger me-3"><i class="bi bi-circle-fill mx-2"></i>Card Vialattea Scadute</span>
                    </span>
                </span>
            </p>
            <nz-table *ngIf="!carica" #tesseramentiTable nzShowSizeChanger                
                [nzTotal]="totale"
                [nzData]="tesseramenti"
                nzNoResult="Nessuna Card Vialattea trovata" 
                [nzPageSize]="pageSize"
                [nzFrontPagination]="false"
                [nzLoading]="loading"             
                [nzPageIndex]="pageIndex"
                [nzBordered]="true" 
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                (nzQueryParams)="onQueryParamsChange($event)"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">                                    
                <thead>
                    <tr>
                        <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="tesseramenti.data_creazione" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="dataFilter" class="text-start">Data Ordine</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="id_ordine" class="text-center">N° Ordine</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="cognome" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="clienteFilter">Cliente</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="data_nascita" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="pagatiFilter" class="text-center">Data di nascita</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="note" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="attiviFilter" class="text-start">Note</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tesseramento of tesseramentiTable.data" [routerLink]="['/tesseramenti/', tesseramento.id]" class="cursor-pointer" [ngClass]="{'bg-gray': tesseramento.attivo == true, 'bg-danger': tesseramento.stato == 'scaduto' }">
                        <td [nzChecked]="setOfCheckedId.has(tesseramento.id) || tesseramento.attivo == true || tesseramento.stato == 'scaduto'" (nzCheckedChange)="onItemChecked(tesseramento.id, $event)" [nzDisabled]="tesseramento.attivo == true || tesseramento.stato == 'scaduto'"></td>
                        <td class="text-start">{{tesseramento.data_creazione | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center">{{tesseramento.id_ordine || '-'}} </td>
                        <td>{{tesseramento.cognome + ' ' + tesseramento.nome}} </td>
                        <td class="text-center">{{tesseramento.data_nascita | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-start">{{tesseramento.note || ''}}</td>                        
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" style="display: flex; justify-content:center; align-items:center;">
            </app-caricamento>
        </div>
    </nz-card>
</div>