<div class="p-3">
    <ng-template #configurazioneTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col">
                        <input nz-input placeholder="Cerca Descrizione" nzSize="large" [(ngModel)]="descrizioneFilter" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">
                <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea una configurazione</button>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="configurazioneTitle">
        <div class="row p-md-3 p-1">
            <nz-table *ngIf="!carica" #configurazioneTable nzShowPagination
                [nzTotal]="(configurazioni | searchConfigurazioneDescrizione: descrizioneFilter).length" nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="configurazioni | searchConfigurazioneDescrizione: descrizioneFilter" [nzBordered]="true" nzNoResult="Nessuna configurazione trovata">
                <thead>
                    <tr>                        
                        <th [nzSortFn]="onOrdinamentoDescrizione" class="text-start">Descrizione</th>
                        <th [nzSortFn]="onOrdinamentoValore">Valore</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let configurazione of configurazioneTable.data | searchConfigurazioneDescrizione: descrizioneFilter"
                        [routerLink]="['/configurazioni/', configurazione.id]" class="cursor-pointer">                        
                        <td class="text-start">{{configurazione.descrizione}}</td>
                        <td>{{configurazione.valore}} </td>
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" style="display: flex; justify-content:center; align-items:center;">
            </app-caricamento>
        </div>
    </nz-card>
</div>