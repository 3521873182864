import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AuthUser, Carrello, CheckOut, Cliente, Prodotto, Stagione } from 'src/app/shared/interfacce';
import { AggiungiProdottiTesseramentoComponent } from '../aggiungi-prodotti-tesseramento/aggiungi-prodotti-tesseramento.component';
import { AggiungiClienteComponent } from 'src/app/clienti/aggiungi-cliente/aggiungi-cliente.component';
import { ModificaProdottiTesseramentiComponent } from '../modifica-prodotti-tesseramenti/modifica-prodotti-tesseramenti.component';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-aggiungi-tesseramento',
  templateUrl: './aggiungi-tesseramento.component.html',
  styleUrls: ['./aggiungi-tesseramento.component.css']
})

export class AggiungiTesseramentoComponent implements OnInit {

  constructor(private router: Router, private dialogRef: MatDialogRef<AggiungiTesseramentoComponent>, private apiService: ApiService, private storageService: StorageService, private datePipe: DatePipe, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { cliente?: Cliente }) { }

  clienti: Cliente[] = [];
  clientiSelezionati: Cliente[] = new Array<Cliente>();
  filteredClienti: Cliente[] = [];

  stagione?: Stagione;

  formTessera!: FormGroup;

  carrello: Carrello[] = [];
  totale: number = 0;

  modifica: boolean = false;

  utente!: AuthUser;

  error_messages = {
    'cliente': [
      { type: 'required', message: "Selezionare un cliente per procedere con la creazione dell'ordine/tesseramento" },
    ]
  }

  ngOnInit(): void {

    // Recupero i dati dell'utente loggato
    this.utente = this.storageService.getUser();

    // Inizializzo i formGroup
    this.formTessera = new FormGroup({
      cliente: new FormControl(null, Validators.required),
      carrello: new FormControl([], Validators.required),
      stagione: new FormControl(null, Validators.required),
      data: new FormControl(null),
      note: new FormControl(null)
    })

    // Recupero la stagione in corso
    this.apiService.getStagione().subscribe({
      next: (data) => this.stagione = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        
        // Associo la stagione in corso al form
        this.formTessera.patchValue({
          stagione: this.datePipe.transform(this.stagione?.inizio_stagione, 'yyyy') + "-" + this.datePipe.transform(this.stagione?.fine_stagione, 'yyyy')
        })
      }
    })

    this.getClienti();
  }

  getClienti() {

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        this.filteredClienti = this.clienti.slice(0, 10);

        // Se è stato passato un cliente procedo
        if (this.data?.cliente) {

          // Filtro il cliente tra la lista dei clienti recuperati
          let cliente = this.clienti.filter(data => data.id == this.data.cliente?.id)

          // Aggiungo il cliente tra i clienti selezionati
          this.clientiSelezionati.push(cliente[0]);
          this.formTessera.patchValue({ cliente: this.clientiSelezionati });
        }
      }
    })

  }

  onSelezionaCliente(clienti: Cliente[]) {
    if (clienti) {
      this.clientiSelezionati = clienti;
      this.carrello = [];
    }
  }

  onCliente() {
    var dialogRef = this.dialog.open(AggiungiClienteComponent, { data: { clienti: this.clienti }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(esito => {
      if (esito) {
        this.getClienti();
      }
    })
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.filtra(ricerca).slice(0, 10)
  }

  onProdotti() {
    var dialogRef = this.dialog.open(AggiungiProdottiTesseramentoComponent, { data: { clienti: this.clientiSelezionati }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe((data: CheckOut) => {
      if (data) {
        this.carrello = data.carrello;
        this.totale = data.totale;
        this.modifica = true;
        this.formTessera.patchValue({ carrello: data.carrello, note: data.note })
      }
    })
  }

  onModificaProdotti() {
    var dialogRef = this.dialog.open(ModificaProdottiTesseramentiComponent, { data: { carrello: this.carrello }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe((data: CheckOut) => {
      if (data) {
        this.carrello = data.carrello;
        this.totale = data.totale;
        this.formTessera.patchValue({ carrello: data.carrello, note: data.note })
      }
    })
  }

  onRimozioneProdotto(prodotto: Prodotto, id_cliente: number) {

    // recupero il carrello del cliente
    let carrello = this.carrello.filter((carrello: Carrello) => carrello.id_cliente == id_cliente)[0];

    // recupero l'indice del prodotto che sto togliendo
    const index = carrello.prodotti.indexOf(prodotto, 0);
    if (index >= -1) {
      carrello.prodotti.splice(index, 1);
    }

    // Utilizza il metodo reduce per sommare il campo "valore" di ciascun oggetto    
    this.totale = this.carrello.reduce((acc, cur) => {
      const sommaParziale = cur.prodotti.reduce((subAcc, subCur) => subAcc + subCur.prezzo, 0);
      return acc + sommaParziale;
    }, 0);

  }

  filtra(name: string): Cliente[] {
    const filterValue = name.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onStagione(stagione: string) {

    let stagione_attuale = this.datePipe.transform(this.stagione?.inizio_stagione, 'yyyy') + "-" + this.datePipe.transform(this.stagione?.fine_stagione, 'yyyy');
    
    // Se la stagione selezionata è diversa rispetto a quella attuale
    if (stagione != stagione_attuale)
      this.modifica = false;

    // Ciclo per il carrello
    for (let cart of this.carrello) {

      // Ciclo per i prodotti
      for (let prodotto of cart.prodotti) {

        // Se il prodotto non è giornaliero o non ha date specifiche
        if (!prodotto.giornaliero || !prodotto.date_specifiche) {

          // Recupero gli anni della stagione
          let split_stagione = stagione.split('-');
          let anno_inizio = split_stagione[0];
          let anno_fine = split_stagione[1];

          // Setto le date in base agli anni della stagione
          let inizio_validita = new Date(prodotto.inizio_validita);
          inizio_validita.setFullYear(+anno_inizio)
          let fine_validita = new Date(prodotto.fine_validita);
          fine_validita.setFullYear(+anno_fine);

          prodotto.inizio_validita = inizio_validita;
          prodotto.fine_validita = fine_validita;
        }
      }

    }
  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let ordine = {
      metodo: null,
      eseguitoDa: this.utente.user,
      data: this.datePipe.transform(this.formTessera.get('data')?.value, 'yyyy-MM-dd'),
      totale: this.totale,
      note: this.formTessera.get('note')?.value
    }

    this.carrello = this.formTessera.get('carrello')?.value;

    // Aggiungo il tesseramento
    this.apiService.addTesseramentoOrdine(ordine, this.carrello).subscribe({
      next: (data) => {
        this.dialogRef.close(true);
        this.router.navigate(['/storico/' + data.id_ordine])
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  onClose() {
    this.dialogRef.close(true);
  }

}
