import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { PuntoRitiro } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-punto-ritiro',
  templateUrl: './modifica-punto-ritiro.component.html',
  styleUrls: ['./modifica-punto-ritiro.component.css']
})
export class ModificaPuntoRitiroComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaPuntoRitiroComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService) { }

  formPuntoRitiro!: FormGroup;

  punto_ritiro?: PuntoRitiro;

  error_messages = {
    'valore': [
      { type: 'required', message: "Inserire un valore per procedere con la creazione di un punto di ritiro" },
    ],
    'descrizione': [
      { type: 'required', message: "Inserire una descrizione per procedere con la creazione di un punto di ritiro" },
    ]
  }

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formPuntoRitiro = new FormGroup({
      valore: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    // Recupero il punto di ritiro in base all'id
    this.apiService.getPuntoRitiro(this.data.id).subscribe({
      next: (data) => this.punto_ritiro = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Associo i dati del punto di ritiro al form
        this.formPuntoRitiro.setValue({
          valore: this.punto_ritiro?.valore,
          descrizione: this.punto_ritiro?.descrizione
        })
      }
    })

  }

  onSubmit() {
    // Formo l'oggetto per l'api
    let punto_ritiro = {
      valore: this.formPuntoRitiro.get('valore')?.value,
      descrizione: this.formPuntoRitiro.get('descrizione')?.value
    }

    // Effettuo l'update del punto di ritiro con i nuovi dati
    this.apiService.updatePuntoRitiro(this.data.id, punto_ritiro).subscribe({
      next: (data) => this.apiService.setPromozioni(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(false);
  }


}
