import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Cliente } from '../shared/interfacce';
import { StampaDettaglioOrdineComponent } from '../storico-ordini/stampa-dettaglio-ordine/stampa-dettaglio-ordine.component';
import * as XLSX from 'xlsx';
import { SearchClientiEmailPipe, SearchClientiNomeCognomePipe } from '../shared/search.pipe';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-clienti-newsletter',
  templateUrl: './clienti-newsletter.component.html',
  styleUrls: ['./clienti-newsletter.component.css']
})
export class ClientiNewsletterComponent implements OnInit {

  constructor(private apiService: ApiService, private location: Location, private dialog: MatDialog, private datePipe: DatePipe, private searchClientiEmail: SearchClientiEmailPipe, private searchClientiNomeCognome: SearchClientiNomeCognomePipe) { }

  clienti: Cliente[] = [];

  clienteFilter: any = '';
  emailFilter: any = '';

  loading: boolean = true;
  carica: boolean = true;
  checked: boolean = false;
  indeterminate: boolean = false;

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  listOfCurrentPageData: readonly Cliente[] = [];
  setOfCheckedId = new Set<number>();

  filtri: { pageIndex: number, pageSize: number, cliente: string, email: string } = { pageIndex: 1, pageSize: 10, cliente: '', email: '' };

  ngOnInit(): void {

    this.loading = true;
    this.apiService.setClienti(this.pageIndex, 10, 'id', 'ascend', this.clienteFilter, this.emailFilter, true);
    this.apiService.clienteChanged.subscribe({
      next: (data) => {
        this.clienti = data;
        this.totale = this.apiService.TotaleClienti;
        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  loadDataFromServer(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setClienti(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.emailFilter, true);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, email: this.emailFilter };

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'id', value: 'ascend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onOrdinamento(a: Cliente, b: Cliente) {
    return 1 - 1;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));

    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onCurrentPageDataChange($event: readonly Cliente[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onExport() {

    this.dialog.closeAll();
    this.dialog.open(StampaDettaglioOrdineComponent, { autoFocus: false, width: '200px' });

    let clienti = this.searchClientiEmail.transform(this.clienti, this.emailFilter);
    clienti = this.searchClientiNomeCognome.transform(clienti, this.clienteFilter);

    let result = [];

    for (let cliente of clienti) {
      result.push({ 'Cognome': cliente.cognome, 'Nome': cliente.nome, 'Data di nascita': this.datePipe.transform(cliente.data_nascita, 'dd/MM/yyyy'), 'Email': cliente.email })
    }

    this.dialog.closeAll();

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(result);

    workSheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Clienti');
    XLSX.writeFile(workBook, 'Clienti.xlsx');

  }

  onAttiva() {

    let clienti = Array.from(this.setOfCheckedId.values());

    this.dialog.closeAll();
    this.dialog.open(StampaDettaglioOrdineComponent, { autoFocus: false, width: '200px' });

    let result: any = [];

    // Ciclo per i clienti selezionati
    for (let id_cliente of clienti) {

      // Ciclo per i clienti che ho salvati
      for (let cliente of this.clienti) {

        // Se trovo il match procedo        
        if (cliente.id == id_cliente) {

          // Aggiungo nell'array i dati del cliente
          result.push({ 'Cognome': cliente.cognome, 'Nome': cliente.nome, 'Data di nascita': this.datePipe.transform(cliente.data_nascita, 'dd/MM/yyyy'), 'Email': cliente.email })
        }
      }


    }

    this.dialog.closeAll();

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(result);

    workSheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Clienti');
    XLSX.writeFile(workBook, 'Clienti.xlsx');


  }

  onIndietro() {
    this.location.back();
  }


}
