import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../service/api.service'
import { Cliente } from 'src/app/shared/interfacce';
import { checkImage } from 'src/app/shared/controlli';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-aggiungi-gruppo',
  templateUrl: './aggiungi-gruppo.component.html',
  styleUrls: ['./aggiungi-gruppo.component.css']
})
export class AggiungiGruppoComponent implements OnInit {

  formGruppo!: FormGroup;

  clienti!: Cliente[];
  filteredClienti: Cliente[] = []

  immagineList: NzUploadFile[] = [];
  immaginePath!: string;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del gruppo' },
      { type: 'minlength', message: 'Inserire almeno 5 caratteri' }
    ],
    'cliente': [
      { type: 'required', message: 'Scegliere un cliente dalla lista per procedere con la creazione del gruppo' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ]
  }

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<AggiungiGruppoComponent>, @Inject(MAT_DIALOG_DATA) public data?: { cliente: Cliente }) { }

  ngOnInit(): void {

    this.formGruppo = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      descrizione: new FormControl(null),
      immagine: new FormControl(null, checkImage()),
      cliente: new FormControl(null, Validators.required)
    })

    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () =>  {      

        this.filteredClienti = this.clienti;

        if (this.data?.cliente) {                
          let cliente = this.clienti.filter(data => data.id == this.data?.cliente.id)
  
          // Aggiungo il cliente tra i clienti selezionati          
          this.formGruppo.patchValue({ cliente: cliente[0] })
        }   
      }
    }) 

  }

  filtra(name: string): Cliente[] {
    const filterValue = name?.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome.trim() + ' ' + cliente.cognome.trim();
      const cognome_nome = cliente.cognome.trim() + ' ' + cliente.nome.trim();
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.filtra(ricerca).slice(0, 10);
  }

  beforeImmagineUpload = (file: any): boolean => {
    this.immagineList = this.immagineList.concat(file);
    this.formGruppo.patchValue({ immagine: file.name });

    const isJpgOrPng = this.immagineList.length > 0 ? this.immagineList[0]!.type === 'image/jpeg' || this.immagineList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.immagineList = [];
      // this.formProdotti.patchValue({ immagine: '' });           
      return false;
    } else {

      const reader = new FileReader();
      reader.onload = () => {
        this.immaginePath = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImmagineUpload = (file: any): boolean => {
    this.formGruppo.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onSubmit() {
    let cliente = this.formGruppo.get('cliente')?.value;

    let gruppo = {
      id_cliente: cliente?.id,
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.formGruppo.get('immagine')?.value || "",
      file_immagine: this.immaginePath || "",
    }

    this.apiService.addGruppo(gruppo).subscribe({
      next: (data) => this.apiService.setGruppi(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })   

  }

  onClose() {
    this.dialogRef.close(true)
  }

}
