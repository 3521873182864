import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Cliente } from '../shared/interfacce';
import { AggiungiClienteComponent } from './aggiungi-cliente/aggiungi-cliente.component';
import * as XLSX from 'xlsx';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.css']
})

export class ClientiComponent implements OnInit {

  clienti: Cliente[] = [];

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  carica: boolean = true;
  loading: boolean = true;

  clienteFilter: any = '';
  emailFilter: any = ''

  filtri: { pageIndex: number, pageSize: number, cliente: string, email: string } = { pageIndex: 1, pageSize: 10, cliente: '', email: '' };

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {

    this.loading = true;
    this.apiService.setClienti(this.pageIndex, 10, 'id', 'ascend', this.clienteFilter, this.emailFilter, false);
    this.apiService.clienteChanged.subscribe({
      next: (data) => {
        this.clienti = data;
        this.totale = this.apiService.TotaleClienti;
        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })

  }

  loadDataFromServer(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setClienti(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.emailFilter, false);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, email: this.emailFilter };

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'id', value: 'ascend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.loadDataFromServer(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.loadDataFromServer(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamento(a: Cliente, b: Cliente) {
    return 1 - 1;
  }

  onExport() {

    let clienti: Cliente[] = [];
    this.apiService.getEsportazioneClienti().subscribe({
      next: (data) => clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(clienti);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Cliente');
        XLSX.writeFile(workBook, 'clienti.xlsx');
      }
    })

  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiClienteComponent, { data: { clienti: this.clienti }, autoFocus: false, width: '1000px' })
  }

}
