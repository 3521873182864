import { Component, OnInit } from '@angular/core';
import { AggiungiCodiciScontoComponent } from './aggiungi-codici-sconto/aggiungi-codici-sconto.component';
import { CodiceSconto } from '../shared/interfacce';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-codici-sconto',
  templateUrl: './codici-sconto.component.html',
  styleUrls: ['./codici-sconto.component.css']
})
export class CodiciScontoComponent implements OnInit {

  codici_sconto: CodiceSconto[] = [];

  carica: boolean = true;

  clienteFilter: any = '';
  codiceFilter: any = '';

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {

    this.apiService.setCodiciSconto();
    this.apiService.codiciScontoChanged.subscribe({
      next: (data) => { this.codici_sconto = data; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { }
    })
  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamentoCliente(a: CodiceSconto, b: CodiceSconto) {
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoCodice(a: CodiceSconto, b: CodiceSconto) {
    return a.codice.localeCompare(b.codice)
  }

  onOrdinamentoDescrizione(a: CodiceSconto, b: CodiceSconto) {
    return a.descrizione.localeCompare(b.descrizione)
  }

  onOrdinamentoValore(a: CodiceSconto, b: CodiceSconto) {
    return (a.valore || 0) - (b.valore || 0)
  }

  onOrdinamentoData(a: CodiceSconto, b: CodiceSconto) {
    return a.fine_validita.toString().localeCompare(b.fine_validita.toString())
  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiCodiciScontoComponent, { autoFocus: false, width: '1000px' })
  }


}
