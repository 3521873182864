import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-modifica-prodotti',
  templateUrl: './modifica-prodotti.component.html',
  styleUrls: ['./modifica-prodotti.component.css']
})
export class ModificaProdottiComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<ModificaProdottiComponent>, @Inject(MAT_DIALOG_DATA) public data: { prodotti: Set<number> }) { }

  formProdotti!: FormGroup;  

  ngOnInit(): void {    

    // Formo il form con i controlli
    this.formProdotti = new FormGroup({
      online: new FormControl(null),
      obbligatorio: new FormControl(null),
      informativo: new FormControl(null),      
      fototessera: new FormControl(null),      
      bonifico: new FormControl(null),
      carta: new FormControl(null),
      satispay: new FormControl(null)
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'update
    let prodotti = {
      prodotti: Array.from(this.data.prodotti.values()),
      online: this.formProdotti.get('online')?.value || false,
      obbligatorio: this.formProdotti.get('obbligatorio')?.value || false,
      informativo: this.formProdotti.get('informativo')?.value || false,      
      fototessera: this.formProdotti.get('fototessera')?.value || false,      
      bonifico: this.formProdotti.get('bonifico')?.value || false,
      carta: this.formProdotti.get('carta')?.value || false,
      satispay: this.formProdotti.get('satispay')?.value || false
    }    

    // Aggiorno i dati dei prodotti selezionati
    this.apiService.updateProdotti(prodotti).subscribe({
      next: (data) => {},
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

  onClose() {
    this.dialogRef.close(true);
  }

}
